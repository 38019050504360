.cb-dialog {
  border: 1px solid #d9d9d9;
  height: auto;
  transition: height 0.35s ease;
}
.cb-dialog .cb-dialog-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
}
.cb-dialog .cb-dialog-container .cb-dialog-header {
  display: flex;
  align-items: center;
  min-height: 30px;
}
.cb-dialog .cb-dialog-container .cb-dialog-header > .cb-glyph {
  margin-right: 16px;
  flex-shrink: 0;
}
.cb-dialog .cb-dialog-container .cb-dialog-header .cb-btn-close {
  margin-left: auto;
}
.cb-dialog .cb-dialog-container .cb-dialog-title {
  font-size: 1.3125rem;
  line-height: 1.1428571429em;
  font-weight: 400;
}
.cb-dialog .cb-dialog-container .cb-dialog-content {
  display: flex;
  flex-direction: column;
  padding-right: 24px;
  padding-left: 48px;
}
.cb-dialog .cb-dialog-container .cb-dialog-content .cb-btn-row {
  margin-top: 24px;
}
.cb-dialog .cb-dialog-container .cb-dialog-header + .cb-dialog-content {
  margin-top: 12px;
}
@media (prefers-reduced-motion: reduce) {
  .cb-dialog {
    transition: none;
  }
}
.cb-dialog.cb-dialog-hide {
  border: 0;
  height: 0;
  overflow: hidden;
  padding: 0;
}