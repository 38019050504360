/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Format transition class name with all the bits.
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
.cb-toast {
  position: relative !important;
  pointer-events: all;
  background: #ffffff;
  align-items: flex-start;
  display: flex;
  border: 1px solid #1e1e1e;
  padding: 0;
  border-radius: 8px;
  width: 498px;
  max-width: 498px;
  margin-top: 16px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  line-height: 1.43em;
  box-sizing: content-box;
}
.cb-toast[aria-hidden=true] {
  display: none;
}
.cb-toast.cb-toast-enter {
  transform: translateY(-48px);
  opacity: 0;
  filter: blur(0);
}
.cb-toast.cb-toast-enter-active {
  transform: translateY(0);
  opacity: 1;
  filter: blur(10px);
  transition-delay: 0;
  transition-duration: 2.4s;
  transition-property: transform, opacity, filter;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.35, 1);
}
.cb-toast.cb-toast-enter ~ .cb-toast {
  transform: translateY(-48px);
}
.cb-toast.cb-toast-enter-active ~ .cb-toast {
  transform: translateY(0);
  transition-delay: 0;
  transition-duration: 2.4s;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.35, 1);
}
.cb-toast.cb-toast-exit {
  opacity: 1;
  filter: blur(0);
}
.cb-toast.cb-toast-exit-active {
  opacity: 0;
  filter: blur(10px);
  transition-delay: 0;
  transition-duration: 2.4s;
  transition-property: opacity, filter;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.35, 1);
}
.cb-toast.cb-toast-exit ~ .cb-toast {
  transform: translateY(0);
}
.cb-toast.cb-toast-exit-active ~ .cb-toast {
  transform: translateY(-48px);
  transition-delay: 0.4s;
  transition-duration: 0.8s;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.35, 1);
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-toast {
    max-width: calc(100% - 48px);
    width: calc(100% - 48px);
    margin-left: 24px;
    margin-right: 24px;
  }
}
.cb-toast .cb-toast-msg,
.cb-toast .cb-toast-action {
  margin: 13px 0 13px 16px;
}
.cb-toast .cb-glyph:not(.cb-x-mark) {
  font-size: 1rem;
  line-height: 18px;
  margin: 14px 0 14px 16px;
}
.cb-toast .cb-glyph.cb-check {
  color: #1d7846;
}
.cb-toast .cb-glyph.cb-exclamation-circle {
  color: #ab2334;
}
.cb-toast .cb-toast-msg {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 16px;
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-toast .cb-toast-msg {
    text-overflow: unset;
    -webkit-line-clamp: unset;
  }
}
.cb-toast .cb-btn-close {
  min-width: 32px;
  margin: 7px 8px 7px 0;
  font-size: 14px !important;
  margin-left: auto !important;
}
.cb-toast a.cb-toast-action {
  font-family: Roboto !important;
  font-weight: 500;
  color: #1e1e1e;
  text-transform: uppercase;
  margin-left: auto !important;
  margin-right: 16px;
}
.cb-toast a.cb-toast-action:hover, .cb-toast a.cb-toast-action.cb-hover, .cb-toast a.cb-toast-action:focus, .cb-toast a.cb-toast-action.cb-focus {
  color: #1e1e1e;
}
.cb-toast a.cb-toast-action:active, .cb-toast a.cb-toast-action.cb-active {
  color: #505050;
}
.cb-toast a.cb-toast-action:visited, .cb-toast a.cb-toast-action.cb-visited {
  color: #1e1e1e;
}
.cb-toast a.cb-toast-action.cb-disabled {
  color: #b2b2b2 !important;
  text-decoration: none !important;
  cursor: not-allowed;
}
.cb-toast a.cb-toast-action.cb-disabled:hover, .cb-toast a.cb-toast-action.cb-disabled.cb-hover, .cb-toast a.cb-toast-action.cb-disabled:focus, .cb-toast a.cb-toast-action.cb-disabled.cb-focus, .cb-toast a.cb-toast-action.cb-disabled.cb-active, .cb-toast a.cb-toast-action.cb-disabled:visited, .cb-toast a.cb-toast-action.cb-disabled.cb-visited {
  color: #ffffff;
  color: #b2b2b2 !important;
  text-decoration: none !important;
}
.cb-toast .cb-toast-action + .cb-btn-close {
  margin-left: 0 !important;
}
.cb-toast.cb-toast-alternative, .cb-toast.cb-toast-success, .cb-toast.cb-toast-warning {
  color: #ffffff;
}
.cb-toast.cb-toast-alternative a, .cb-toast.cb-toast-success a, .cb-toast.cb-toast-warning a {
  color: #ffffff !important;
}
.cb-toast.cb-toast-alternative a:hover, .cb-toast.cb-toast-alternative a.cb-hover, .cb-toast.cb-toast-alternative a:focus, .cb-toast.cb-toast-alternative a.cb-focus, .cb-toast.cb-toast-success a:hover, .cb-toast.cb-toast-success a.cb-hover, .cb-toast.cb-toast-success a:focus, .cb-toast.cb-toast-success a.cb-focus, .cb-toast.cb-toast-warning a:hover, .cb-toast.cb-toast-warning a.cb-hover, .cb-toast.cb-toast-warning a:focus, .cb-toast.cb-toast-warning a.cb-focus {
  color: #ffffff;
}
.cb-toast.cb-toast-alternative a:active, .cb-toast.cb-toast-alternative a.cb-active, .cb-toast.cb-toast-success a:active, .cb-toast.cb-toast-success a.cb-active, .cb-toast.cb-toast-warning a:active, .cb-toast.cb-toast-warning a.cb-active {
  color: #ffffff;
}
.cb-toast.cb-toast-alternative a:visited, .cb-toast.cb-toast-alternative a.cb-visited, .cb-toast.cb-toast-success a:visited, .cb-toast.cb-toast-success a.cb-visited, .cb-toast.cb-toast-warning a:visited, .cb-toast.cb-toast-warning a.cb-visited {
  color: #ffffff;
}
.cb-toast.cb-toast-alternative a.cb-disabled, .cb-toast.cb-toast-success a.cb-disabled, .cb-toast.cb-toast-warning a.cb-disabled {
  color: #b2b2b2 !important;
  text-decoration: none !important;
  cursor: not-allowed;
}
.cb-toast.cb-toast-alternative a.cb-disabled:hover, .cb-toast.cb-toast-alternative a.cb-disabled.cb-hover, .cb-toast.cb-toast-alternative a.cb-disabled:focus, .cb-toast.cb-toast-alternative a.cb-disabled.cb-focus, .cb-toast.cb-toast-alternative a.cb-disabled.cb-active, .cb-toast.cb-toast-alternative a.cb-disabled:visited, .cb-toast.cb-toast-alternative a.cb-disabled.cb-visited, .cb-toast.cb-toast-success a.cb-disabled:hover, .cb-toast.cb-toast-success a.cb-disabled.cb-hover, .cb-toast.cb-toast-success a.cb-disabled:focus, .cb-toast.cb-toast-success a.cb-disabled.cb-focus, .cb-toast.cb-toast-success a.cb-disabled.cb-active, .cb-toast.cb-toast-success a.cb-disabled:visited, .cb-toast.cb-toast-success a.cb-disabled.cb-visited, .cb-toast.cb-toast-warning a.cb-disabled:hover, .cb-toast.cb-toast-warning a.cb-disabled.cb-hover, .cb-toast.cb-toast-warning a.cb-disabled:focus, .cb-toast.cb-toast-warning a.cb-disabled.cb-focus, .cb-toast.cb-toast-warning a.cb-disabled.cb-active, .cb-toast.cb-toast-warning a.cb-disabled:visited, .cb-toast.cb-toast-warning a.cb-disabled.cb-visited {
  color: #ffffff;
  color: #b2b2b2 !important;
  text-decoration: none !important;
}
.cb-toast.cb-toast-alternative .cb-glyph.cb-check, .cb-toast.cb-toast-alternative .cb-glyph.cb-exclamation-circle, .cb-toast.cb-toast-success .cb-glyph.cb-check, .cb-toast.cb-toast-success .cb-glyph.cb-exclamation-circle, .cb-toast.cb-toast-warning .cb-glyph.cb-check, .cb-toast.cb-toast-warning .cb-glyph.cb-exclamation-circle {
  color: #ffffff !important;
}
.cb-toast.cb-toast-alternative .cb-btn-close, .cb-toast.cb-toast-success .cb-btn-close, .cb-toast.cb-toast-warning .cb-btn-close {
  color: #ffffff;
  background: transparent;
}
.cb-toast.cb-toast-alternative .cb-btn-close:focus, .cb-toast.cb-toast-success .cb-btn-close:focus, .cb-toast.cb-toast-warning .cb-btn-close:focus {
  color: #1e1e1e !important;
  background-color: #f0f0f0 !important;
}
.cb-toast.cb-toast-alternative {
  background: #1e1e1e;
  border-color: #505050;
}
.cb-toast.cb-toast-alternative .cb-btn-close.cb-btn-greyscale:hover {
  background-color: #4b4b4b;
  box-shadow: 0 0 0 2px #ffffff;
  color: #ffffff;
}
.cb-toast.cb-toast-alternative .cb-btn-close.cb-btn-greyscale:focus {
  box-shadow: none;
}
.cb-toast.cb-toast-success {
  background: #1d7846;
}
.cb-toast.cb-toast-success .cb-btn-close.cb-btn-greyscale:hover {
  background-color: #176038;
  color: #ffffff;
}
.cb-toast.cb-toast-success .cb-btn-close.cb-btn-greyscale:focus {
  box-shadow: none;
}
.cb-toast.cb-toast-warning {
  background: #ab2334;
}
.cb-toast.cb-toast-warning .cb-btn-close.cb-btn-greyscale:hover {
  background-color: #891c2a;
  color: #ffffff;
}
.cb-toast.cb-toast-warning .cb-btn-close.cb-btn-greyscale:focus {
  box-shadow: none;
}

.cb-toast-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: auto;
  pointer-events: none;
  display: flex !important;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  z-index: 1080;
}
.cb-toast-container.cb-toast-container-bottom {
  bottom: 0;
  flex-direction: column-reverse;
  top: auto;
}
.cb-toast-container.cb-toast-container-bottom .cb-toast.cb-toast-enter:not(.cb-toast-enter-active), .cb-toast-container.cb-toast-container-bottom .cb-toast.cb-toast-enter:not(.cb-toast-enter-active) ~ .cb-toast, .cb-toast-container.cb-toast-container-bottom .cb-toast.cb-toast-appear:not(.cb-toast-appear-active), .cb-toast-container.cb-toast-container-bottom .cb-toast.cb-toast-appear:not(.cb-toast-appear-active) ~ .cb-toast, .cb-toast-container.cb-toast-container-bottom .cb-toast.cb-toast-exit-active ~ .cb-toast, .cb-toast-container.cb-toast-container-bottom .cb-toast.cb-toast-leave-active ~ .cb-toast {
  transform: translateY(64px);
}