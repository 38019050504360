.cb-dropdown {
  position: relative;
}
.cb-dropdown a {
  color: #1e1e1e;
}
.cb-dropdown a:hover, .cb-dropdown a.cb-hover, .cb-dropdown a:focus, .cb-dropdown a.cb-focus {
  color: #1e1e1e;
}
.cb-dropdown a:active, .cb-dropdown a.cb-active {
  color: #505050;
}
.cb-dropdown a:visited, .cb-dropdown a.cb-visited {
  color: #1e1e1e;
}
.cb-dropdown a.cb-disabled {
  color: #b2b2b2 !important;
  text-decoration: none !important;
  cursor: not-allowed;
}
.cb-dropdown a.cb-disabled:hover, .cb-dropdown a.cb-disabled.cb-hover, .cb-dropdown a.cb-disabled:focus, .cb-dropdown a.cb-disabled.cb-focus, .cb-dropdown a.cb-disabled.cb-active, .cb-dropdown a.cb-disabled:visited, .cb-dropdown a.cb-disabled.cb-visited {
  color: #ffffff;
  color: #b2b2b2 !important;
  text-decoration: none !important;
}
.cb-dropdown > a {
  display: inline-flex;
  align-items: flex-end;
  padding: 0 24px 12px;
  height: 48px;
}
.cb-dropdown > a:hover, .cb-dropdown > a:active, .cb-dropdown > a:focus, .cb-dropdown > a.cb-open {
  box-shadow: inset 0px -3px 0px -1px #1e1e1e;
}
.cb-dropdown > a:focus {
  outline: 0;
}
.cb-dropdown.cb-disabled > a {
  color: #b2b2b2;
}
.cb-dropdown.cb-disabled > a:hover, .cb-dropdown.cb-disabled > a:focus, .cb-dropdown.cb-disabled > a:active {
  color: #b2b2b2;
  cursor: not-allowed;
  box-shadow: none;
}
.cb-dropdown.cb-dropdown-condensed {
  height: 40px;
}
.cb-dropdown .cb-dropdown-toggle {
  font-size: 0.875rem !important;
  line-height: 16px;
  text-decoration: none !important;
  transition: background-color 0.25s ease-in-out;
  text-align: center;
}
.cb-dropdown .cb-dropdown-toggle:hover, .cb-dropdown .cb-dropdown-toggle:active, .cb-dropdown .cb-dropdown-toggle:focus {
  text-decoration: none;
}
.cb-dropdown .cb-dropdown-toggle .cb-glyph {
  margin-right: 8px;
}
.cb-dropdown .cb-dropdown-toggle .cb-glyph.cb-down, .cb-dropdown .cb-dropdown-toggle .cb-glyph.cb-up {
  font-size: 0.75rem;
  margin-left: 8px;
  margin-right: 0;
}
.cb-dropdown .cb-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 899;
  display: none;
  min-width: 100%;
  background-color: #ffffff;
  font-family: Roboto !important;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.7142857143em;
}
.cb-dropdown .cb-dropdown-menu.cb-menu-right, .cb-dropdown .cb-dropdown-menu.cb-menu-right-always {
  right: 0;
  left: auto;
}
.cb-dropdown .cb-dropdown-menu .cb-input-container {
  padding: 24px 24px 16px 24px;
  margin-bottom: 12px;
  border-bottom: 1px solid #d9d9d9;
}
.cb-dropdown .cb-dropdown-menu .cb-input-container .cb-input-helper {
  margin-top: 8px;
}
.cb-dropdown .cb-dropdown-menu ul {
  list-style: none;
  padding: 12px 0px;
}
.cb-dropdown .cb-dropdown-menu ul > li > a {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  font-size: 0.875rem;
  line-height: 1.1428571429em;
  text-decoration: none;
  transition: background-color 0.25s ease-in-out;
  transition: box-shadow 0.25s ease-in-out;
  white-space: nowrap;
}
@media (max-width: 1023.98px) {
  .cb-dropdown .cb-dropdown-menu ul > li > a {
    padding: 16px 24px;
  }
}
.cb-dropdown .cb-dropdown-menu ul > li > a:focus {
  text-decoration: underline;
}
.cb-dropdown .cb-dropdown-menu ul > li > a:hover, .cb-dropdown .cb-dropdown-menu ul > li > a:active {
  background-color: #f0f0f0;
  box-shadow: inset 2px 0px 0px 0px #1e1e1e;
  text-decoration: none;
}
.cb-dropdown .cb-dropdown-menu ul > li > a:focus[aria-disabled=true] {
  box-shadow: inset 0 0 0 2px #d9d9d9;
}
.cb-dropdown .cb-dropdown-menu ul > li > a .cb-glyph {
  color: #b2b2b2;
  margin-left: 8px;
}
.cb-dropdown .cb-dropdown-menu ul > li > a.cb-selected {
  box-shadow: inset 2px 0px 0px 0px #1e1e1e;
}
.cb-dropdown .cb-dropdown-menu ul > li.cb-disabled > a:hover, .cb-dropdown .cb-dropdown-menu ul > li.cb-disabled > a:active {
  box-shadow: none;
}
.cb-dropdown .cb-dropdown-menu ul.cb-dropdown-left-icon > li > a {
  justify-content: flex-start;
}
.cb-dropdown .cb-dropdown-menu ul.cb-dropdown-left-icon > li > a .cb-glyph {
  margin-left: 0;
  margin-right: 8px;
}
.cb-dropdown .cb-dropdown-menu .cb-input-container + ul {
  padding-top: 0;
}
.cb-dropdown .cb-dropdown-menu li.cb-dropdown-header {
  padding: 4px 24px;
}
.cb-dropdown .cb-dropdown-menu li.cb-dropdown-header h3,
.cb-dropdown .cb-dropdown-menu li.cb-dropdown-header h4,
.cb-dropdown .cb-dropdown-menu li.cb-dropdown-header h5 {
  font-size: 0.75rem;
  line-height: 1.3333333333em;
  color: #888888;
  font-weight: 700;
}
.cb-dropdown .cb-dropdown-menu li.cb-disabled a {
  color: #b2b2b2;
}
.cb-dropdown .cb-dropdown-menu li.cb-disabled a:hover, .cb-dropdown .cb-dropdown-menu li.cb-disabled a:focus, .cb-dropdown .cb-dropdown-menu li.cb-disabled a:active {
  color: #b2b2b2;
  cursor: not-allowed;
}
.cb-dropdown .cb-dropdown-menu li.cb-divider {
  height: 1px;
  overflow: hidden;
  margin: 12px 0;
  border-top: 1px solid #d9d9d9;
}
.cb-dropdown .cb-dropdown-menu li input:focus {
  outline: 0;
}
.cb-dropdown.cb-open .cb-dropdown-menu {
  display: block;
}
.cb-dropdown.cb-open .cb-dropdown-menu.cb-dropdown-menu-scroll {
  overflow-y: auto;
  min-height: 64px;
}
@media (max-width: 1023.98px) {
  .cb-dropdown.cb-open .cb-dropdown-menu.cb-dropdown-menu-scroll {
    min-height: 72px;
  }
}

[data-cb-input-m=keyboard] .cb-dropdown .cb-dropdown-menu ul > li > a:focus {
  outline: 0;
  box-shadow: inset 0 0 0 2px #7ab6fd !important;
  text-decoration: none !important;
}
[data-cb-input-m=keyboard] .cb-dropdown .cb-dropdown-menu ul > li > a:focus[aria-disabled=true] {
  outline: 0;
  box-shadow: inset 0 0 0 2px #7ab6fd;
  text-decoration: none !important;
}