.cb-date-picker {
  width: 306px;
  min-width: 306px;
  max-width: 306px;
}
.cb-date-picker .cb-popover-header {
  display: flex;
  align-items: center;
}
.cb-date-picker .cb-popover-header .cb-date-picker-month {
  display: inline-flex;
  justify-content: center;
  font-size: 19px;
  font-weight: 500;
  line-height: 1.26;
  width: calc(100% - 48px);
}
.cb-date-picker .cb-popover-header .cb-date-picker-month:nth-of-type(2) {
  display: none;
}
.cb-date-picker .cb-popover-header .cb-date-picker-month .cb-month-label + .cb-year-label {
  margin-left: 8px;
}
.cb-date-picker .cb-popover-header .cb-prev-month,
.cb-date-picker .cb-popover-header .cb-next-month {
  border: 0;
  color: #1e1e1e;
}
.cb-date-picker .cb-popover-header .cb-prev-month:hover,
.cb-date-picker .cb-popover-header .cb-next-month:hover {
  background-color: #e6edf8;
  box-shadow: inset 0 0 0 1px #e6edf8;
}
.cb-date-picker .cb-popover-header .cb-prev-month:focus,
.cb-date-picker .cb-popover-header .cb-next-month:focus {
  background-color: transparent;
}
.cb-date-picker.cb-date-picker-double {
  width: 654px;
  min-width: 654px;
  max-width: 654px;
}
.cb-date-picker.cb-date-picker-double .cb-popover-header .cb-date-picker-month {
  width: 232px;
}
.cb-date-picker.cb-date-picker-double .cb-popover-header .cb-date-picker-month:nth-of-type(2) {
  display: flex;
  margin-left: 92px;
}
.cb-date-picker .cb-month-container {
  display: flex;
  justify-content: space-between;
}
.cb-date-picker .cb-popover-inner {
  padding-bottom: 16px;
}

.cb.is-hidden {
  display: none;
}
.cb-month {
  padding: 0;
  width: 252px;
  min-width: 252px;
}
.cb-days-of-the-week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
.cb-day-of-the-week {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
}
.cb-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
.cb-day {
  display: flex;
  height: 36px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  cursor: default;
  transition: background-image 0.35s ease;
}
.cb-day.is-today {
  color: #324dc7;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg  viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' %3E %3Ccircle  stroke='%23f5f7fc' fill='%23f5f7fc' stroke-width='1' cx='16' cy='16' r='15'/%3E  %3C/svg%3E");
}
.cb-day:not(.is-disabled):hover {
  background-size: contain;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg  viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' %3E  %3Ccircle  stroke='%231e1e1e' fill='transparent' stroke-width='1' cx='16' cy='16' r='15'/%3E  %3C/svg%3E");
}
.cb-day.is-previous-month, .cb-day.is-next-month {
  opacity: 0.3;
}
.cb-day.cb-day.is-in-range:not(.is-disabled) {
  opacity: 1;
}
.cb-day.is-in-range {
  background-color: #f5f7fc;
  background-image: none;
}
.cb-day.is-disabled {
  background: none;
  background-color: #f0f0f0;
  color: #505050;
  pointer-events: none;
}
.cb-day.is-start-date.is-in-range {
  background-color: transparent;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg  viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' %3E %3Crect x='16' y='0' width='120' height='32' stroke-width='1' stroke='%23f5f7fc' fill='%23f5f7fc'/%3E% %3Ccircle  stroke='%23324dc7' fill='%23324dc7' stroke-width='1' cx='16' cy='16' r='15'/%3E  %3C/svg%3E");
}
.cb-day.is-start-date.is-available:not(.is-in-range) {
  background-color: transparent;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg  viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' %3E %3Ccircle  stroke='%23324dc7' fill='%23324dc7' stroke-width='1' cx='16' cy='16' r='15'/%3E  %3C/svg%3E");
}
.cb-day.is-end-date.is-in-range {
  background-color: transparent;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg  viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' %3E %3Crect x='0' y='0' width='16' height='32' stroke-width='1' stroke='%23f5f7fc' fill='%23f5f7fc'/%3E% %3Ccircle  stroke='%23324dc7' fill='%23324dc7' stroke-width='1' cx='16' cy='16' r='15'/%3E  %3C/svg%3E");
}
.cb-day.is-start-date.is-end-date {
  background-color: transparent;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg  viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' %3E %3Ccircle  stroke='%23324dc7' fill='%23324dc7' stroke-width='1' cx='16' cy='16' r='15'/%3E  %3C/svg%3E");
}
.cb-day.is-start-date, .cb-day.is-end-date, .cb-day.is-start-date:hover, .cb-day.is-end-date:hover {
  background-size: auto;
  background-position: center;
  color: #fff;
}
.cb-day.is-disabled.is-start-date, .cb-day.is-disabled.is-end-date, .cb-day.is-disabled.is-start-date:hover, .cb-day.is-disabled.is-end-date:hover {
  color: #505050;
  background-color: #e6edf8;
}
.cb-day a {
  text-decoration: none;
  color: inherit !important;
  display: flex;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  justify-content: center;
  align-items: center;
}
.cb-day a:hover, .cb-day a:focus, .cb-day a:visited, .cb-day a:active {
  color: inherit !important;
  text-decoration: none;
}

.cb-date-picker.cb-date-picker-double .cb-month {
  width: 280px;
  min-width: 280px;
}
.cb-date-picker.cb-date-picker-double .cb-day {
  height: 40px;
}

.cb-input-date-picker {
  position: relative;
}
.cb-input-date-picker .cb-line {
  display: block;
  border-right: 1px solid #d9d9d9;
  position: absolute;
  left: 143px;
  top: 10px;
  width: 1px;
  height: 27px;
  z-index: 1;
}
.cb-input-date-picker.cb-focus-fl .cb-line {
  display: none;
}
.cb-input-date-picker .cb-input {
  display: inline-flex;
}
.cb-input-date-picker .cb-input label {
  width: calc(100% - 34px);
  justify-content: left;
}
.cb-input-date-picker .cb-input label.cb-value-fl, .cb-input-date-picker .cb-input label.cb-focus-fl, .cb-input-date-picker .cb-input label.cb-fill-fl {
  width: fit-content !important;
}
.cb-input-date-picker .cb-input.cb-date-input input {
  width: 144px;
}
.cb-input-date-picker .cb-input.cb-date-input-start input {
  width: 144px;
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.cb-input-date-picker .cb-input.cb-date-input-end input {
  width: 120px;
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.cb-input-date-picker .cb-input:first-of-type label {
  width: calc(100% - 42px);
}

[data-cb-input-m=keyboard] .cb-date-picker.cb-popover:focus {
  box-shadow: inset 0 0 0 2px #324dc7;
}