.cb-input-mask.cb-floating-label label {
  z-index: 1;
  width: calc(100% - 32px);
  justify-content: flex-start;
}
.cb-input-mask.cb-floating-label input::placeholder {
  color: transparent;
  transition: color 0.35s ease;
}
.cb-input-mask.cb-floating-label .cb-value-fl,
.cb-input-mask.cb-floating-label .cb-focus-fl {
  width: auto;
  justify-content: center;
}
.cb-input-mask.cb-floating-label .cb-value-fl + input::placeholder,
.cb-input-mask.cb-floating-label .cb-focus-fl + input::placeholder {
  color: #888888;
}