.cb-photo-gallery ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin-left: -24px;
  margin-top: -48px;
}
.cb-photo-gallery ul li {
  list-style: none;
  margin: 0;
  flex-shrink: 0;
  margin-top: 48px;
  margin-left: 24px;
}
.cb-photo-gallery ul li .cb-photo-thumb img {
  overflow: hidden;
  opacity: 1;
  transition: opacity 200ms;
}
.cb-photo-gallery ul li .cb-photo-thumb img:not([data-cb-lazy]) {
  width: 100%;
  height: auto;
  max-height: 100%;
}
.cb-photo-gallery ul li .cb-photo-thumb img.cb-active-effect {
  width: 100%;
  height: auto;
  max-height: 100%;
}
@media (prefers-reduced-motion: reduce) {
  .cb-photo-gallery ul li .cb-photo-thumb img {
    transition: none;
  }
}
.cb-photo-gallery ul li .cb-photo-thumb + .cb-photo-thumb-info {
  margin-top: 16px;
}
.cb-photo-gallery ul li .cb-photo-thumb-info h2,
.cb-photo-gallery ul li .cb-photo-thumb-info .cb-h2,
.cb-photo-gallery ul li .cb-photo-thumb-info h3,
.cb-photo-gallery ul li .cb-photo-thumb-info .cb-h3,
.cb-photo-gallery ul li .cb-photo-thumb-info h4,
.cb-photo-gallery ul li .cb-photo-thumb-info .cb-h4,
.cb-photo-gallery ul li .cb-photo-thumb-info h5,
.cb-photo-gallery ul li .cb-photo-thumb-info .cb-h5 {
  color: #1e1e1e;
  font-weight: 500;
  font-size: 1.3125rem;
  line-height: 1.1428571429em;
  margin: 0;
}
@media (max-width: 1247.98px) {
  .cb-photo-gallery ul li .cb-photo-thumb-info h2,
.cb-photo-gallery ul li .cb-photo-thumb-info .cb-h2,
.cb-photo-gallery ul li .cb-photo-thumb-info h3,
.cb-photo-gallery ul li .cb-photo-thumb-info .cb-h3,
.cb-photo-gallery ul li .cb-photo-thumb-info h4,
.cb-photo-gallery ul li .cb-photo-thumb-info .cb-h4,
.cb-photo-gallery ul li .cb-photo-thumb-info h5,
.cb-photo-gallery ul li .cb-photo-thumb-info .cb-h5 {
    font-size: 1.1875rem;
    line-height: 1.2631578947em;
  }
}
.cb-photo-gallery ul li .cb-photo-thumb-info h2 + p,
.cb-photo-gallery ul li .cb-photo-thumb-info .cb-h2 + p,
.cb-photo-gallery ul li .cb-photo-thumb-info h3 + p,
.cb-photo-gallery ul li .cb-photo-thumb-info .cb-h3 + p,
.cb-photo-gallery ul li .cb-photo-thumb-info h4 + p,
.cb-photo-gallery ul li .cb-photo-thumb-info .cb-h4 + p,
.cb-photo-gallery ul li .cb-photo-thumb-info h5 + p,
.cb-photo-gallery ul li .cb-photo-thumb-info .cb-h5 + p {
  margin-top: 4px;
}
.cb-photo-gallery ul li .cb-photo-thumb-info p {
  display: none;
  color: #1e1e1e;
}
.cb-photo-gallery ul li .cb-photo-thumb-info p:first-of-type {
  display: block;
  margin-bottom: 0;
}
.cb-photo-gallery ul li a {
  color: #1e1e1e;
  text-decoration: none;
}
.cb-photo-gallery ul li a:hover *, .cb-photo-gallery ul li a:active *, .cb-photo-gallery ul li a.cb-focus * {
  color: #1e1e1e;
  text-decoration: underline;
}
.cb-photo-gallery ul li a.cb-focus {
  text-decoration: underline;
  cursor: pointer;
}
.cb-photo-gallery ul li a.cb-focus img {
  opacity: 0.7;
}
.cb-photo-gallery ul li .cb-photo-details-info {
  display: none;
  visibility: hidden;
}
.cb-photo-gallery ul.cb-photo-gallery-grid {
  margin-right: -12px;
  margin-left: -12px;
}
.cb-photo-gallery ul.cb-photo-gallery-grid li {
  margin-top: 48px;
  margin-left: 0;
}
.cb-photo-gallery ul.cb-photo-gallery-5 li {
  width: calc((100% - (24px * 5)) / 5);
}
.cb-photo-gallery ul.cb-photo-gallery-4 li {
  width: calc((100% - (24px * 4)) / 4);
}
.cb-photo-gallery ul.cb-photo-gallery-3 li {
  width: calc((100% - (24px * 3)) / 3);
}
.cb-photo-gallery ul.cb-photo-gallery-2 li {
  width: calc((100% - (24px * 2)) / 2);
}
.cb-photo-gallery ul.cb-photo-gallery-2 .cb-photo-thumb {
  height: 144px;
}
.cb-photo-gallery ul.cb-photo-gallery-1 li {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}
.cb-photo-gallery ul.cb-photo-gallery-1 li .cb-photo-thumb {
  height: 144px;
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-photo-gallery ul {
    flex-direction: column;
    padding: 0 0 0 24px;
  }
  .cb-photo-gallery ul li {
    width: 100% !important;
    margin-left: 0;
    margin-right: 0;
  }
  .cb-photo-gallery ul li .cb-photo-thumb {
    height: auto !important;
  }
  .cb-photo-gallery ul li .cb-photo-thumb img {
    width: 100%;
    max-width: auto;
  }
}
.cb-photo-gallery.cb-no-desc ul {
  margin-top: -24px;
}
.cb-photo-gallery.cb-no-desc ul li {
  margin-top: 24px;
}

.cb-photo-gallery-modal .cb-modal-container {
  position: relative;
  display: flex;
  flex-direction: column;
}
.cb-photo-gallery-modal .cb-modal-container .cb-modal-content {
  height: calc(100vh - 141px) !important;
  margin-top: 141px;
  padding-bottom: 141px;
  background-color: #1e1e1e;
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-header {
  width: 100%;
  height: 141px;
  background-color: #1e1e1e;
  color: #ffffff;
  padding: 0 41px;
  margin-bottom: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-header .cb-gallery-counter {
  text-align: center;
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-header .cb-gallery-counter.cb-btn-3 {
  width: calc(100% - 128px);
  padding-left: 128px;
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-header .cb-gallery-counter.cb-btn-2 {
  width: calc(100% - 80px);
  padding-left: 80px;
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-header .cb-gallery-counter.cb-btn-1 {
  width: calc(100% - 32px);
  padding-left: 32px;
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-header .cb-gallery-counter .cb-count-info {
  display: inline-flex;
  font-size: 0.875rem;
  margin-bottom: 0;
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-header .cb-gallery-counter .cb-count-info span:nth-of-type(2) {
  margin-left: 6px;
  margin-right: 6px;
  display: inline-block;
  width: 10px;
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-header .cb-gallery-controls .cb-btn {
  position: relative;
  border: transparent;
  width: 2em;
  height: 2rem;
  font-size: 1rem;
  line-height: 1em;
  padding: 0;
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-header .cb-gallery-controls .cb-btn.cb-btn-sm, .cb-photo-gallery-modal .cb-modal-container .cb-gallery-header .cb-gallery-controls .cb-btn.cb-btn-small {
  width: 1.5rem;
  height: 1.5rem;
  line-height: 0.875em;
  font-size: 0.875rem;
  padding: 0;
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-header .cb-gallery-controls .cb-btn.cb-disabled, .cb-photo-gallery-modal .cb-modal-container .cb-gallery-header .cb-gallery-controls .cb-btn:disabled {
  opacity: 1;
  text-decoration: none !important;
  background-color: transparent;
  border-color: transparent;
  color: #888888 !important;
  cursor: not-allowed;
  box-shadow: none;
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-header .cb-gallery-controls .cb-btn.cb-disabled span, .cb-photo-gallery-modal .cb-modal-container .cb-gallery-header .cb-gallery-controls .cb-btn:disabled span {
  text-decoration: none !important;
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-header .cb-gallery-controls .cb-btn:focus, .cb-photo-gallery-modal .cb-modal-container .cb-gallery-header .cb-gallery-controls .cb-btn.cb-focus {
  background-color: transparent !important;
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-header .cb-gallery-controls .cb-btn.cb-white-color {
  background: transparent;
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-header .cb-gallery-controls .cb-btn.cb-white-color:hover {
  background-color: #1e1e1e !important;
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-header .cb-gallery-controls .cb-btn + .cb-btn {
  margin-left: 16px;
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-container {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #1e1e1e;
}
@media (max-width: 1023.98px) {
  .cb-photo-gallery-modal .cb-modal-container .cb-gallery-container {
    height: calc(100vh - 71px) !important;
    overflow-y: auto;
  }
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-container .cb-gallery-navigation {
  width: 144px;
  transition: width 0.25s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (prefers-reduced-motion: reduce) {
  .cb-photo-gallery-modal .cb-modal-container .cb-gallery-container .cb-gallery-navigation {
    transition: none;
  }
}
@media (min-width: 1024px) and (max-width: 1247.98px) {
  .cb-photo-gallery-modal .cb-modal-container .cb-gallery-container .cb-gallery-navigation {
    width: 96px;
  }
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-container .cb-gallery-navigation span {
  font-size: 3rem;
  width: 0.5em;
  height: 1em;
  opacity: 0.5;
  color: #ffffff;
  transition: opacity 300ms;
}
@media (max-width: 1023.98px) {
  .cb-photo-gallery-modal .cb-modal-container .cb-gallery-container .cb-gallery-navigation span {
    font-size: 1.5rem;
  }
}
@media (prefers-reduced-motion: reduce) {
  .cb-photo-gallery-modal .cb-modal-container .cb-gallery-container .cb-gallery-navigation span {
    transition: none;
  }
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-container .cb-gallery-navigation:hover, .cb-photo-gallery-modal .cb-modal-container .cb-gallery-container .cb-gallery-navigation.hover {
  cursor: pointer;
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-container .cb-gallery-navigation:hover span, .cb-photo-gallery-modal .cb-modal-container .cb-gallery-container .cb-gallery-navigation.hover span {
  opacity: 1;
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-container .cb-gallery-content {
  position: relative;
  display: flex;
  width: calc(100% - 288px);
}
@media (min-width: 1024px) and (max-width: 1247.98px) {
  .cb-photo-gallery-modal .cb-modal-container .cb-gallery-container .cb-gallery-content {
    width: calc(100% - 192px);
  }
}
@media (min-width: 1024px) {
  .cb-photo-gallery-modal .cb-modal-container .cb-gallery-container .cb-gallery-content {
    justify-content: center;
  }
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-container .cb-gallery-content .cb-gallery-image {
  width: 66.6666666667%;
  background-color: #ffffff;
  height: inherit;
  position: relative;
  transition: width 0.25s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .cb-photo-gallery-modal .cb-modal-container .cb-gallery-container .cb-gallery-content .cb-gallery-image {
    transition: none;
  }
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-container .cb-gallery-content .cb-gallery-image .cb-gallery-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-container .cb-gallery-content .cb-gallery-image .cb-gallery-image-container img {
  transition: opacity 0.25s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .cb-photo-gallery-modal .cb-modal-container .cb-gallery-container .cb-gallery-content .cb-gallery-image .cb-gallery-image-container img {
    transition: none;
  }
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-container .cb-gallery-content .cb-gallery-image .cb-gallery-image-container.cb-has-fullscreen img:hover {
  cursor: zoom-in;
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-container .cb-gallery-content .cb-gallery-image .cb-gallery-image-container.cb-hide-effect img {
  opacity: 0;
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-container .cb-gallery-content .cb-gallery-image .cb-gallery-image-container.cb-active-effect img {
  opacity: 1;
}
@media (min-width: 1024px) {
  .cb-photo-gallery-modal .cb-modal-container .cb-gallery-container .cb-gallery-content.cb-image-padding:not(.cb-no-info) .cb-gallery-image .cb-gallery-image-container {
    padding: 24px 0 24px 24px;
  }
  .cb-photo-gallery-modal .cb-modal-container .cb-gallery-container .cb-gallery-content.cb-image-padding.cb-no-info .cb-gallery-image {
    background: transparent;
  }
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-container .cb-gallery-content .cb-info-container {
  display: flex;
  flex-direction: column;
  width: 33.3333333333%;
  background-color: #ffffff;
  padding: 48px;
  min-height: 96px;
}
@media (min-width: 1024px) and (max-width: 1247.98px) {
  .cb-photo-gallery-modal .cb-modal-container .cb-gallery-container .cb-gallery-content .cb-info-container {
    padding: 24px;
  }
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-container .cb-gallery-content .cb-info-container .cb-gallery-des {
  width: 100%;
  overflow-y: auto;
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-container .cb-gallery-content .cb-info-container .cb-gallery-des h4,
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-container .cb-gallery-content .cb-info-container .cb-gallery-des h5 {
  font-size: 1rem;
  line-height: 1.5em;
  margin: 0;
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-container .cb-gallery-content .cb-info-container .cb-gallery-des .cb-main-title {
  font-size: 1.188rem;
  line-height: 30px;
  margin: 0;
  padding: 0;
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-container .cb-gallery-content .cb-info-container .cb-gallery-des .cb-main-info {
  color: 16px;
  margin: 0;
  padding: 0;
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-container .cb-gallery-content .cb-info-container .cb-gallery-des .cb-sub-title {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1em;
  margin: 0.75em 0 0.375em;
  margin-top: 30px;
}
.cb-photo-gallery-modal .cb-modal-container .cb-gallery-container .cb-gallery-content .cb-info-container.cb-no-info {
  display: none;
}
@media (max-width: 1023.98px) {
  .cb-photo-gallery-modal .cb-modal-container .cb-gallery-header {
    width: 100%;
    height: 71px;
    padding: 0 24px;
  }
  .cb-photo-gallery-modal .cb-modal-container .cb-gallery-header .cb-gallery-counter {
    text-align: left;
    display: flex;
    padding-left: 0 !important;
  }
  .cb-photo-gallery-modal .cb-modal-container .cb-modal-content {
    position: relative;
    height: calc(100vh - 71px) !important;
    margin-top: 71px;
    padding-bottom: 0;
  }
  .cb-photo-gallery-modal .cb-modal-container .cb-modal-content .cb-gallery-container {
    height: auto;
  }
  .cb-photo-gallery-modal .cb-modal-container .cb-modal-content .cb-gallery-container .cb-gallery-navigation {
    width: 24px;
  }
  .cb-photo-gallery-modal .cb-modal-container .cb-modal-content .cb-gallery-container .cb-gallery-content {
    width: calc(100% - 48px);
    justify-content: top;
    height: 100%;
    overflow-y: auto;
    flex-direction: column;
  }
  .cb-photo-gallery-modal .cb-modal-container .cb-modal-content .cb-gallery-container .cb-gallery-content .cb-gallery-image {
    width: 100%;
    position: relative;
    height: auto;
  }
  .cb-photo-gallery-modal .cb-modal-container .cb-modal-content .cb-gallery-container .cb-gallery-content .cb-gallery-image .cb-gallery-image-container {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cb-photo-gallery-modal .cb-modal-container .cb-modal-content .cb-gallery-container .cb-gallery-content .cb-gallery-image .cb-gallery-hit {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .cb-photo-gallery-modal .cb-modal-container .cb-modal-content .cb-gallery-container .cb-gallery-content .cb-info-container {
    width: 100%;
    padding: 24px;
    min-height: fit-content;
    flex: 1 1 auto;
  }
}
.cb-photo-gallery-modal.cb-no-desc .cb-modal-container .cb-gallery-container .cb-gallery-content {
  width: 100%;
}
.cb-photo-gallery-modal.cb-no-desc .cb-modal-container .cb-gallery-container .cb-gallery-content .cb-gallery-image {
  width: 100%;
  background-color: transparent;
}
.cb-photo-gallery-modal.cb-no-desc .cb-modal-container .cb-gallery-container .cb-gallery-content .cb-info-container {
  display: none;
}
@media (max-width: 1023.98px) {
  .cb-photo-gallery-modal.cb-no-desc .cb-modal-container .cb-gallery-container .cb-gallery-content .cb-gallery-image .cb-gallery-image-container {
    height: calc(100vh - 71px);
  }
}
.cb-photo-gallery-modal.cb-single-img .cb-modal-container .cb-gallery-header .cb-gallery-controls {
  margin-left: auto;
}
.cb-photo-gallery-modal.cb-single-img .cb-modal-container .cb-gallery-header .cb-gallery-controls .cb-btn-play {
  display: none;
}
.cb-photo-gallery-modal.cb-single-img .cb-modal-container .cb-gallery-header .cb-gallery-counter {
  display: none;
}
.cb-photo-gallery-modal.cb-single-img .cb-modal-container .cb-gallery-container .cb-gallery-content {
  width: 100%;
}
@media (max-width: 1023.98px) {
  .cb-photo-gallery-modal.cb-single-img .cb-modal-container .cb-gallery-container .cb-gallery-content .cb-gallery-image::after {
    padding-top: calc(100% - 41px);
  }
}
.cb-photo-gallery-modal.cb-photo-full .cb-modal-container .cb-gallery-container .cb-gallery-content .cb-gallery-image .cb-gallery-image-container.cb-has-fullscreen img:hover {
  cursor: zoom-out !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}