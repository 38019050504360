.cb-glyph:not(.cb-glyph-numeric) {
  font-family: CB Icons;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cb-glyph.cb-online-tool::before {
  content: "\e936";
}
.cb-glyph.cb-download::before {
  content: "\e914";
}
.cb-glyph.cb-batch-download::before {
  content: "\e902";
}
.cb-glyph.cb-menu-local::before {
  content: "\e925";
}
.cb-glyph.cb-filter::before {
  content: "\e91d";
}
.cb-glyph.cb-sort::before {
  content: "\e947";
}
.cb-glyph.cb-sort-desc::before {
  content: "\e913";
}
.cb-glyph.cb-sort-asc::before {
  content: "\e950";
}
.cb-glyph.cb-up::before {
  content: "\e950";
}
.cb-glyph.cb-down::before {
  content: "\e913";
}
.cb-glyph.cb-left::before {
  content: "\e921";
}
.cb-glyph.cb-right::before {
  content: "\e940";
}
.cb-glyph.cb-dbl-left::before {
  content: "\e910";
}
.cb-glyph.cb-dbl-right::before {
  content: "\e911";
}
.cb-glyph.cb-mail::before {
  content: "\e928";
}
.cb-glyph.cb-fax::before {
  content: "\e91c";
}
.cb-glyph.cb-home::before {
  content: "\e91f";
}
.cb-glyph.cb-location::before {
  content: "\e926";
}
.cb-glyph.cb-phone-alt::before {
  content: "\e938";
}
.cb-glyph.cb-resources::before {
  content: "\e93f";
}
.cb-glyph.cb-cal-full::before {
  content: "\e907";
}
.cb-glyph.cb-east::before {
  content: "\e916";
}
.cb-glyph.cb-cal-empty::before {
  content: "\e906";
}
.cb-glyph.cb-note::before {
  content: "\e935";
}
.cb-glyph.cb-lightbulb::before {
  content: "\e922";
}
.cb-glyph.cb-question::before {
  content: "\e93c";
}
.cb-glyph.cb-megaphone::before {
  content: "\e92a";
}
.cb-glyph.cb-plus::before {
  content: "\e93a";
}
.cb-glyph.cb-minus::before {
  content: "\e92c";
}
.cb-glyph.cb-chat-bubble::before {
  content: "\e909";
}
.cb-glyph.cb-facebook::before {
  content: "\e91b";
}
.cb-glyph.cb-linkedin::before {
  content: "\e923";
}
.cb-glyph.cb-twitter::before {
  content: "\e94f";
}
.cb-glyph.cb-youtube::before {
  content: "\e956";
}
.cb-glyph.cb-instagram::before {
  content: "\e920";
}
.cb-glyph.cb-compose::before {
  content: "\e90d";
}
.cb-glyph.cb-upload::before {
  content: "\e951";
}
.cb-glyph.cb-list::before {
  content: "\e924";
}
.cb-glyph.cb-user::before {
  content: "\e952";
}
.cb-glyph.cb-settings::before {
  content: "\e944";
}
.cb-glyph.cb-error::before {
  content: "\e918";
}
.cb-glyph.cb-book::before {
  content: "\e905";
}
.cb-glyph.cb-tag::before {
  content: "\e94c";
}
.cb-glyph.cb-check::before {
  content: "\e90b";
}
.cb-glyph.cb-menu::before {
  content: "\e92b";
}
.cb-glyph.cb-exclamation::before {
  content: "\e919";
}
.cb-glyph.cb-draft-in::before {
  content: "\e915";
}
.cb-glyph.cb-new-window::before {
  content: "\e930";
}
.cb-glyph.cb-no-draft::before {
  content: "\e931";
}
.cb-glyph.cb-nav-links::before {
  content: "\e92d";
}
.cb-glyph.cb-quote-right::before {
  content: "\e93e";
}
.cb-glyph.cb-quote-left::before {
  content: "\e93d";
}
.cb-glyph.cb-x-mark::before {
  content: "\e955";
}
.cb-glyph.cb-circle::before {
  content: "\e90c";
}
.cb-glyph.cb-disc::before {
  content: "\e912";
}
.cb-glyph.cb-north-east::before {
  content: "\e932";
}
.cb-glyph.cb-creditcard::before {
  content: "\e90f";
}
.cb-glyph.cb-educator::before {
  content: "\e917";
}
.cb-glyph.cb-series::before {
  content: "\e943";
}
.cb-glyph.cb-bell::before {
  content: "\e904";
}
.cb-glyph.cb-pending::before {
  content: "\e937";
}
.cb-glyph.cb-signed-in::before {
  content: "\e946";
}
.cb-glyph.cb-matrix::before {
  content: "\e929";
}
.cb-glyph.cb-search::before {
  content: "\e942";
}
.cb-glyph.cb-cart::before {
  content: "\e908";
}
.cb-glyph.cb-globe::before {
  content: "\e91e";
}
.cb-glyph.cb-acorn::before {
  content: "\e957";
}
.cb-glyph.cb-batch-upload::before {
  content: "\e903";
}
.cb-glyph.cb-needs-score::before {
  content: "\e92e";
}
.cb-glyph.cb-test-scored::before {
  content: "\e94e";
}
.cb-glyph.cb-save-date::before {
  content: "\e941";
}
.cb-glyph.cb-north-west::before {
  content: "\e933";
}
.cb-glyph.cb-west::before {
  content: "\e953";
}
.cb-glyph.cb-south-west::before {
  content: "\e94a";
}
.cb-glyph.cb-south-east::before {
  content: "\e949";
}
.cb-glyph.cb-barchart::before {
  content: "\e901";
}
.cb-glyph.cb-print::before {
  content: "\e93b";
}
.cb-glyph.cb-chat::before {
  content: "\e90a";
}
.cb-glyph.cb-window::before {
  content: "\e954";
}
.cb-glyph.cb-bag::before {
  content: "\e900";
}
.cb-glyph.cb-expand-window::before {
  content: "\e91a";
}
.cb-glyph.cb-task-complete::before {
  content: "\e94d";
}
.cb-glyph.cb-new-doc::before {
  content: "\e92f";
}
.cb-glyph.cb-sound::before {
  content: "\e948";
}
.cb-glyph.cb-computer::before {
  content: "\e90e";
}
.cb-glyph.cb-share::before {
  content: "\e945";
}
.cb-glyph.cb-lock::before {
  content: "\e927";
}
.cb-glyph.cb-phone::before {
  content: "\e939";
}
.cb-glyph.cb-disc-alt::before {
  content: "\e958";
}
.cb-glyph.cb-box-fill::before {
  content: "\e959";
}
.cb-glyph.cb-box::before {
  content: "\e95a";
}
.cb-glyph.cb-play::before {
  content: "\e95b";
}
.cb-glyph.cb-check-alt::before {
  content: "\e95c";
}
.cb-glyph.cb-check-circle::before {
  content: "\e95d";
}
.cb-glyph.cb-see-on::before {
  content: "\e95e";
}
.cb-glyph.cb-see-off::before {
  content: "\e95f";
}
.cb-glyph.cb-exclamation-circle::before {
  content: "\e960";
}
.cb-glyph.cb-full-screen::before {
  content: "\e961";
}
.cb-glyph.cb-exit-full-screen::before {
  content: "\e962";
}
.cb-glyph.cb-slideshow-play::before {
  content: "\e963";
}
.cb-glyph.cb-slideshow-pause::before {
  content: "\e964";
}
.cb-glyph.cb-gallery-forward::before {
  content: "\e966";
}
.cb-glyph.cb-gallery-backward::before {
  content: "\e965";
}
.cb-glyph.cb-carousel-backward::before {
  content: "\e967";
}
.cb-glyph.cb-carousel-forward::before {
  content: "\e968";
}
.cb-glyph.cb-carousel-backward-sm::before {
  content: "\e969";
}
.cb-glyph.cb-carousel-forward-sm::before {
  content: "\e96a";
}
.cb-glyph.cb-refresh::before {
  content: "\e96b";
}
.cb-glyph.cb-attachment::before {
  content: "\e96c";
}
.cb-glyph.cb-order-desc::before {
  content: "\e96d";
}
.cb-glyph.cb-order-asc::before {
  content: "\e96e";
}
.cb-glyph.cb-laptop::before {
  content: "\e96f";
}
.cb-glyph.cb-search {
  background: none;
}

.cb-glyph-numeric {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: inline-block;
  border-radius: 50%;
  font-size: 1rem;
  width: 2em;
  height: 2em;
  font-weight: 700;
  line-height: 1.82em !important;
  text-align: center;
  border: 0.125em solid #1e1e1e;
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .cb-glyph-numeric {
      line-height: 2em !important;
    }
  }
}
.cb-glyph-numeric::before {
  content: attr(data-cb-number);
}
.cb-glyph-numeric.cb-glyph-circular {
  color: #ffffff;
  border-color: #009cde;
  background-color: #009cde;
}

.cb-glyph-circular:not(.cb-glyph-numeric) {
  position: relative;
  display: block;
  height: 2em;
  width: 2em;
}
.cb-glyph-circular:not(.cb-glyph-numeric)::before {
  position: absolute;
  z-index: 2;
  left: 0.5em;
  top: 0.5em;
  color: #ffffff;
}
.cb-glyph-circular:not(.cb-glyph-numeric)::after {
  content: " ";
  position: absolute;
  z-index: 1;
  display: block;
  border-radius: 50%;
  height: 2em;
  width: 2em;
  background-color: #009cde;
}

.cb-glyph-avatar:not(.cb-glyph-numeric) {
  transition: all 0.35s ease;
  position: relative;
  display: block;
  font-size: 1rem;
  width: 2em;
  height: 2em;
}
.cb-glyph-avatar:not(.cb-glyph-numeric)::before {
  position: absolute;
  z-index: 2;
  font-size: 1.3125em;
  left: 0.25em;
  top: 0.25em;
  color: #ffffff;
}
.cb-glyph-avatar:not(.cb-glyph-numeric)::after {
  content: " ";
  position: absolute;
  z-index: 1;
  display: block;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  background-color: #1e1e1e;
}
@media (min-width: 1024px) {
  .cb-glyph-avatar:not(.cb-glyph-numeric) {
    width: 3em;
    height: 3em;
  }
  .cb-glyph-avatar:not(.cb-glyph-numeric)::before {
    font-size: 2em;
  }
  .cb-glyph-avatar:not(.cb-glyph-numeric)::after {
    width: 3em;
    height: 3em;
  }
}

.cb-glyph:not(.cb-glyph-avatar).cb-glyph-xs,
.cb-glyph-numeric:not(.cb-glyph-avatar).cb-glyph-xs {
  font-size: calc(1rem * 1);
}
.cb-glyph:not(.cb-glyph-avatar).cb-glyph-sm,
.cb-glyph-numeric:not(.cb-glyph-avatar).cb-glyph-sm {
  font-size: calc(1rem * 2);
}
.cb-glyph:not(.cb-glyph-avatar).cb-glyph-md,
.cb-glyph-numeric:not(.cb-glyph-avatar).cb-glyph-md {
  font-size: calc(1rem * 3);
}
.cb-glyph:not(.cb-glyph-avatar).cb-glyph-lg,
.cb-glyph-numeric:not(.cb-glyph-avatar).cb-glyph-lg {
  font-size: calc(1rem * 4);
}
.cb-glyph:not(.cb-glyph-avatar).cb-glyph-xl,
.cb-glyph-numeric:not(.cb-glyph-avatar).cb-glyph-xl {
  font-size: calc(1rem * 5);
}

.cb-glyph.cb-glyph-avatar.cb-glyph-sm {
  width: 2em;
  height: 2em;
}
.cb-glyph.cb-glyph-avatar.cb-glyph-sm::before {
  font-size: 1.3125em;
  left: 0.25em;
  top: 0.25em;
}
.cb-glyph.cb-glyph-avatar.cb-glyph-sm::after {
  width: 2em;
  height: 2em;
}
.cb-glyph.cb-glyph-avatar.cb-glyph-md {
  width: 3em;
  height: 3em;
}
.cb-glyph.cb-glyph-avatar.cb-glyph-md::before {
  font-size: 2em;
}
.cb-glyph.cb-glyph-avatar.cb-glyph-md::after {
  width: 3em;
  height: 3em;
}

.cb-glyph.cb-glyph-12 {
  font-size: 12px;
}
.cb-glyph.cb-glyph-circular.cb-glyph-24 {
  font-size: 12px;
}

.cb-glyph.cb-glyph-14 {
  font-size: 14px;
}
.cb-glyph.cb-glyph-circular.cb-glyph-28 {
  font-size: 14px;
}

.cb-glyph.cb-glyph-16 {
  font-size: 16px;
}
.cb-glyph.cb-glyph-circular.cb-glyph-32 {
  font-size: 16px;
}

@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-glyph-xs-12 {
    font-size: 12px !important;
  }
  .cb-glyph-circular.cb-glyph-xs-24 {
    font-size: 12px !important;
  }
  .cb-glyph-xs-14 {
    font-size: 14px !important;
  }
  .cb-glyph-circular.cb-glyph-xs-28 {
    font-size: 14px !important;
  }
  .cb-glyph-xs-16 {
    font-size: 16px !important;
  }
  .cb-glyph-circular.cb-glyph-xs-32 {
    font-size: 16px !important;
  }
}
@media (min-width: 1px) {
  .cb-glyph-xs-up-12 {
    font-size: 12px !important;
  }
  .cb-glyph-circular.cb-glyph-xs-up-24 {
    font-size: 12px !important;
  }
  .cb-glyph-xs-up-14 {
    font-size: 14px !important;
  }
  .cb-glyph-circular.cb-glyph-xs-up-28 {
    font-size: 14px !important;
  }
  .cb-glyph-xs-up-16 {
    font-size: 16px !important;
  }
  .cb-glyph-circular.cb-glyph-xs-up-32 {
    font-size: 16px !important;
  }
}
@media (max-width: 767.98px) {
  .cb-glyph-xs-down-12 {
    font-size: 12px !important;
  }
  .cb-glyph-circular.cb-glyph-xs-down-24 {
    font-size: 12px !important;
  }
  .cb-glyph-xs-down-14 {
    font-size: 14px !important;
  }
  .cb-glyph-circular.cb-glyph-xs-down-28 {
    font-size: 14px !important;
  }
  .cb-glyph-xs-down-16 {
    font-size: 16px !important;
  }
  .cb-glyph-circular.cb-glyph-xs-down-32 {
    font-size: 16px !important;
  }
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .cb-glyph-sm-12 {
    font-size: 12px !important;
  }
  .cb-glyph-circular.cb-glyph-sm-24 {
    font-size: 12px !important;
  }
  .cb-glyph-sm-14 {
    font-size: 14px !important;
  }
  .cb-glyph-circular.cb-glyph-sm-28 {
    font-size: 14px !important;
  }
  .cb-glyph-sm-16 {
    font-size: 16px !important;
  }
  .cb-glyph-circular.cb-glyph-sm-32 {
    font-size: 16px !important;
  }
}
@media (min-width: 768px) {
  .cb-glyph-sm-up-12 {
    font-size: 12px !important;
  }
  .cb-glyph-circular.cb-glyph-sm-up-24 {
    font-size: 12px !important;
  }
  .cb-glyph-sm-up-14 {
    font-size: 14px !important;
  }
  .cb-glyph-circular.cb-glyph-sm-up-28 {
    font-size: 14px !important;
  }
  .cb-glyph-sm-up-16 {
    font-size: 16px !important;
  }
  .cb-glyph-circular.cb-glyph-sm-up-32 {
    font-size: 16px !important;
  }
}
@media (max-width: 1023.98px) {
  .cb-glyph-sm-down-12 {
    font-size: 12px !important;
  }
  .cb-glyph-circular.cb-glyph-sm-down-24 {
    font-size: 12px !important;
  }
  .cb-glyph-sm-down-14 {
    font-size: 14px !important;
  }
  .cb-glyph-circular.cb-glyph-sm-down-28 {
    font-size: 14px !important;
  }
  .cb-glyph-sm-down-16 {
    font-size: 16px !important;
  }
  .cb-glyph-circular.cb-glyph-sm-down-32 {
    font-size: 16px !important;
  }
}
@media (min-width: 1024px) and (max-width: 1247.98px) {
  .cb-glyph-md-12 {
    font-size: 12px !important;
  }
  .cb-glyph-circular.cb-glyph-md-24 {
    font-size: 12px !important;
  }
  .cb-glyph-md-14 {
    font-size: 14px !important;
  }
  .cb-glyph-circular.cb-glyph-md-28 {
    font-size: 14px !important;
  }
  .cb-glyph-md-16 {
    font-size: 16px !important;
  }
  .cb-glyph-circular.cb-glyph-md-32 {
    font-size: 16px !important;
  }
}
@media (min-width: 1024px) {
  .cb-glyph-md-up-12 {
    font-size: 12px !important;
  }
  .cb-glyph-circular.cb-glyph-md-up-24 {
    font-size: 12px !important;
  }
  .cb-glyph-md-up-14 {
    font-size: 14px !important;
  }
  .cb-glyph-circular.cb-glyph-md-up-28 {
    font-size: 14px !important;
  }
  .cb-glyph-md-up-16 {
    font-size: 16px !important;
  }
  .cb-glyph-circular.cb-glyph-md-up-32 {
    font-size: 16px !important;
  }
}
@media (max-width: 1247.98px) {
  .cb-glyph-md-down-12 {
    font-size: 12px !important;
  }
  .cb-glyph-circular.cb-glyph-md-down-24 {
    font-size: 12px !important;
  }
  .cb-glyph-md-down-14 {
    font-size: 14px !important;
  }
  .cb-glyph-circular.cb-glyph-md-down-28 {
    font-size: 14px !important;
  }
  .cb-glyph-md-down-16 {
    font-size: 16px !important;
  }
  .cb-glyph-circular.cb-glyph-md-down-32 {
    font-size: 16px !important;
  }
}
@media (min-width: 1248px) and (max-width: 1343.98px) {
  .cb-glyph-lg-12 {
    font-size: 12px !important;
  }
  .cb-glyph-circular.cb-glyph-lg-24 {
    font-size: 12px !important;
  }
  .cb-glyph-lg-14 {
    font-size: 14px !important;
  }
  .cb-glyph-circular.cb-glyph-lg-28 {
    font-size: 14px !important;
  }
  .cb-glyph-lg-16 {
    font-size: 16px !important;
  }
  .cb-glyph-circular.cb-glyph-lg-32 {
    font-size: 16px !important;
  }
}
@media (min-width: 1248px) {
  .cb-glyph-lg-up-12 {
    font-size: 12px !important;
  }
  .cb-glyph-circular.cb-glyph-lg-up-24 {
    font-size: 12px !important;
  }
  .cb-glyph-lg-up-14 {
    font-size: 14px !important;
  }
  .cb-glyph-circular.cb-glyph-lg-up-28 {
    font-size: 14px !important;
  }
  .cb-glyph-lg-up-16 {
    font-size: 16px !important;
  }
  .cb-glyph-circular.cb-glyph-lg-up-32 {
    font-size: 16px !important;
  }
}
@media (max-width: 1343.98px) {
  .cb-glyph-lg-down-12 {
    font-size: 12px !important;
  }
  .cb-glyph-circular.cb-glyph-lg-down-24 {
    font-size: 12px !important;
  }
  .cb-glyph-lg-down-14 {
    font-size: 14px !important;
  }
  .cb-glyph-circular.cb-glyph-lg-down-28 {
    font-size: 14px !important;
  }
  .cb-glyph-lg-down-16 {
    font-size: 16px !important;
  }
  .cb-glyph-circular.cb-glyph-lg-down-32 {
    font-size: 16px !important;
  }
}
@media (min-width: 1344px) and (max-width: 1439.98px) {
  .cb-glyph-xl-12 {
    font-size: 12px !important;
  }
  .cb-glyph-circular.cb-glyph-xl-24 {
    font-size: 12px !important;
  }
  .cb-glyph-xl-14 {
    font-size: 14px !important;
  }
  .cb-glyph-circular.cb-glyph-xl-28 {
    font-size: 14px !important;
  }
  .cb-glyph-xl-16 {
    font-size: 16px !important;
  }
  .cb-glyph-circular.cb-glyph-xl-32 {
    font-size: 16px !important;
  }
}
@media (min-width: 1344px) {
  .cb-glyph-xl-up-12 {
    font-size: 12px !important;
  }
  .cb-glyph-circular.cb-glyph-xl-up-24 {
    font-size: 12px !important;
  }
  .cb-glyph-xl-up-14 {
    font-size: 14px !important;
  }
  .cb-glyph-circular.cb-glyph-xl-up-28 {
    font-size: 14px !important;
  }
  .cb-glyph-xl-up-16 {
    font-size: 16px !important;
  }
  .cb-glyph-circular.cb-glyph-xl-up-32 {
    font-size: 16px !important;
  }
}
@media (max-width: 1439.98px) {
  .cb-glyph-xl-down-12 {
    font-size: 12px !important;
  }
  .cb-glyph-circular.cb-glyph-xl-down-24 {
    font-size: 12px !important;
  }
  .cb-glyph-xl-down-14 {
    font-size: 14px !important;
  }
  .cb-glyph-circular.cb-glyph-xl-down-28 {
    font-size: 14px !important;
  }
  .cb-glyph-xl-down-16 {
    font-size: 16px !important;
  }
  .cb-glyph-circular.cb-glyph-xl-down-32 {
    font-size: 16px !important;
  }
}
@media (min-width: 1440px) and (max-width: 1727.98px) {
  .cb-glyph-2xl-12 {
    font-size: 12px !important;
  }
  .cb-glyph-circular.cb-glyph-2xl-24 {
    font-size: 12px !important;
  }
  .cb-glyph-2xl-14 {
    font-size: 14px !important;
  }
  .cb-glyph-circular.cb-glyph-2xl-28 {
    font-size: 14px !important;
  }
  .cb-glyph-2xl-16 {
    font-size: 16px !important;
  }
  .cb-glyph-circular.cb-glyph-2xl-32 {
    font-size: 16px !important;
  }
}
@media (min-width: 1440px) {
  .cb-glyph-2xl-up-12 {
    font-size: 12px !important;
  }
  .cb-glyph-circular.cb-glyph-2xl-up-24 {
    font-size: 12px !important;
  }
  .cb-glyph-2xl-up-14 {
    font-size: 14px !important;
  }
  .cb-glyph-circular.cb-glyph-2xl-up-28 {
    font-size: 14px !important;
  }
  .cb-glyph-2xl-up-16 {
    font-size: 16px !important;
  }
  .cb-glyph-circular.cb-glyph-2xl-up-32 {
    font-size: 16px !important;
  }
}
@media (max-width: 1727.98px) {
  .cb-glyph-2xl-down-12 {
    font-size: 12px !important;
  }
  .cb-glyph-circular.cb-glyph-2xl-down-24 {
    font-size: 12px !important;
  }
  .cb-glyph-2xl-down-14 {
    font-size: 14px !important;
  }
  .cb-glyph-circular.cb-glyph-2xl-down-28 {
    font-size: 14px !important;
  }
  .cb-glyph-2xl-down-16 {
    font-size: 16px !important;
  }
  .cb-glyph-circular.cb-glyph-2xl-down-32 {
    font-size: 16px !important;
  }
}
@media (min-width: 1728px) {
  .cb-glyph-3xl-12 {
    font-size: 12px !important;
  }
  .cb-glyph-circular.cb-glyph-3xl-24 {
    font-size: 12px !important;
  }
  .cb-glyph-3xl-14 {
    font-size: 14px !important;
  }
  .cb-glyph-circular.cb-glyph-3xl-28 {
    font-size: 14px !important;
  }
  .cb-glyph-3xl-16 {
    font-size: 16px !important;
  }
  .cb-glyph-circular.cb-glyph-3xl-32 {
    font-size: 16px !important;
  }
}
@media (min-width: 1728px) {
  .cb-glyph-3xl-up-12 {
    font-size: 12px !important;
  }
  .cb-glyph-circular.cb-glyph-3xl-up-24 {
    font-size: 12px !important;
  }
  .cb-glyph-3xl-up-14 {
    font-size: 14px !important;
  }
  .cb-glyph-circular.cb-glyph-3xl-up-28 {
    font-size: 14px !important;
  }
  .cb-glyph-3xl-up-16 {
    font-size: 16px !important;
  }
  .cb-glyph-circular.cb-glyph-3xl-up-32 {
    font-size: 16px !important;
  }
}
.cb-glyph-3xl-down-12 {
  font-size: 12px !important;
}

.cb-glyph-circular.cb-glyph-3xl-down-24 {
  font-size: 12px !important;
}

.cb-glyph-3xl-down-14 {
  font-size: 14px !important;
}

.cb-glyph-circular.cb-glyph-3xl-down-28 {
  font-size: 14px !important;
}

.cb-glyph-3xl-down-16 {
  font-size: 16px !important;
}

.cb-glyph-circular.cb-glyph-3xl-down-32 {
  font-size: 16px !important;
}

.cb-glyph-numeric.cb-glyph-24 {
  font-size: 12px;
  flex-shrink: 0;
}
.cb-glyph-numeric.cb-glyph-circular.cb-glyph-24 {
  font-size: 12px;
  flex-shrink: 0;
}
.cb-glyph-numeric.cb-glyph-24 {
  line-height: 23px !important;
}

.cb-glyph-numeric.cb-glyph-28 {
  font-size: 14px;
  flex-shrink: 0;
}
.cb-glyph-numeric.cb-glyph-circular.cb-glyph-28 {
  font-size: 14px;
  flex-shrink: 0;
}
.cb-glyph-numeric.cb-glyph-24 {
  line-height: 23px !important;
}

.cb-glyph-numeric.cb-glyph-32 {
  font-size: 16px;
  flex-shrink: 0;
}
.cb-glyph-numeric.cb-glyph-circular.cb-glyph-32 {
  font-size: 16px;
  flex-shrink: 0;
}
.cb-glyph-numeric.cb-glyph-24 {
  line-height: 23px !important;
}

.cb-glyph-numeric.cb-glyph-48 {
  font-size: 24px;
  flex-shrink: 0;
}
.cb-glyph-numeric.cb-glyph-circular.cb-glyph-48 {
  font-size: 24px;
  flex-shrink: 0;
}
.cb-glyph-numeric.cb-glyph-24 {
  line-height: 23px !important;
}

.cb-glyph-numeric.cb-glyph-64 {
  font-size: 32px;
  flex-shrink: 0;
}
.cb-glyph-numeric.cb-glyph-circular.cb-glyph-64 {
  font-size: 32px;
  flex-shrink: 0;
}
.cb-glyph-numeric.cb-glyph-24 {
  line-height: 23px !important;
}

@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-glyph-numeric.cb-glyph-xs-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-xs-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-xs-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-xs-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-xs-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-xs-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-xs-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-xs-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-xs-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-xs-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1px) {
  .cb-glyph-numeric.cb-glyph-xs-up-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-xs-up-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-xs-up-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-xs-up-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-xs-up-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-xs-up-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-xs-up-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-xs-up-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-xs-up-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-xs-up-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (max-width: 767.98px) {
  .cb-glyph-numeric.cb-glyph-xs-down-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-xs-down-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-xs-down-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-xs-down-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-xs-down-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-xs-down-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-xs-down-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-xs-down-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-xs-down-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-xs-down-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .cb-glyph-numeric.cb-glyph-sm-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-sm-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-sm-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-sm-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-sm-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-sm-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-sm-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-sm-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-sm-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-sm-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 768px) {
  .cb-glyph-numeric.cb-glyph-sm-up-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-sm-up-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-sm-up-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-sm-up-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-sm-up-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-sm-up-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-sm-up-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-sm-up-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-sm-up-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-sm-up-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (max-width: 1023.98px) {
  .cb-glyph-numeric.cb-glyph-sm-down-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-sm-down-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-sm-down-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-sm-down-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-sm-down-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-sm-down-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-sm-down-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-sm-down-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-sm-down-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-sm-down-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1024px) and (max-width: 1247.98px) {
  .cb-glyph-numeric.cb-glyph-md-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-md-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-md-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-md-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-md-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-md-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-md-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-md-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-md-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-md-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1024px) {
  .cb-glyph-numeric.cb-glyph-md-up-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-md-up-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-md-up-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-md-up-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-md-up-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-md-up-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-md-up-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-md-up-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-md-up-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-md-up-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (max-width: 1247.98px) {
  .cb-glyph-numeric.cb-glyph-md-down-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-md-down-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-md-down-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-md-down-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-md-down-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-md-down-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-md-down-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-md-down-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-md-down-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-md-down-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1248px) and (max-width: 1343.98px) {
  .cb-glyph-numeric.cb-glyph-lg-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-lg-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-lg-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-lg-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-lg-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-lg-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-lg-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-lg-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-lg-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-lg-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1248px) {
  .cb-glyph-numeric.cb-glyph-lg-up-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-lg-up-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-lg-up-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-lg-up-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-lg-up-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-lg-up-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-lg-up-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-lg-up-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-lg-up-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-lg-up-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (max-width: 1343.98px) {
  .cb-glyph-numeric.cb-glyph-lg-down-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-lg-down-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-lg-down-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-lg-down-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-lg-down-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-lg-down-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-lg-down-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-lg-down-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-lg-down-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-lg-down-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1344px) and (max-width: 1439.98px) {
  .cb-glyph-numeric.cb-glyph-xl-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-xl-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-xl-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-xl-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-xl-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-xl-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-xl-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-xl-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-xl-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-xl-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1344px) {
  .cb-glyph-numeric.cb-glyph-xl-up-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-xl-up-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-xl-up-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-xl-up-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-xl-up-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-xl-up-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-xl-up-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-xl-up-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-xl-up-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-xl-up-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (max-width: 1439.98px) {
  .cb-glyph-numeric.cb-glyph-xl-down-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-xl-down-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-xl-down-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-xl-down-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-xl-down-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-xl-down-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-xl-down-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-xl-down-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-xl-down-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-xl-down-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1440px) and (max-width: 1727.98px) {
  .cb-glyph-numeric.cb-glyph-2xl-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-2xl-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-2xl-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-2xl-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-2xl-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-2xl-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-2xl-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-2xl-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-2xl-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-2xl-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1440px) {
  .cb-glyph-numeric.cb-glyph-2xl-up-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-2xl-up-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-2xl-up-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-2xl-up-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-2xl-up-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-2xl-up-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-2xl-up-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-2xl-up-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-2xl-up-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-2xl-up-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (max-width: 1727.98px) {
  .cb-glyph-numeric.cb-glyph-2xl-down-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-2xl-down-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-2xl-down-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-2xl-down-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-2xl-down-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-2xl-down-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-2xl-down-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-2xl-down-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-2xl-down-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-2xl-down-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1728px) {
  .cb-glyph-numeric.cb-glyph-3xl-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-3xl-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-3xl-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-3xl-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-3xl-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-3xl-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-3xl-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-3xl-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-3xl-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-3xl-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1728px) {
  .cb-glyph-numeric.cb-glyph-3xl-up-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-3xl-up-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-3xl-up-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-3xl-up-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-3xl-up-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-3xl-up-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-3xl-up-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-3xl-up-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-3xl-up-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-glyph-numeric.cb-glyph-circular.cb-glyph-3xl-up-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
.cb-glyph-numeric.cb-glyph-3xl-down-24 {
  font-size: 12px !important;
  flex-shrink: 0;
}
.cb-glyph-numeric.cb-glyph-circular.cb-glyph-3xl-down-24 {
  font-size: 12px !important;
  flex-shrink: 0;
}

.cb-glyph-numeric.cb-glyph-3xl-down-28 {
  font-size: 14px !important;
  flex-shrink: 0;
}
.cb-glyph-numeric.cb-glyph-circular.cb-glyph-3xl-down-28 {
  font-size: 14px !important;
  flex-shrink: 0;
}

.cb-glyph-numeric.cb-glyph-3xl-down-32 {
  font-size: 16px !important;
  flex-shrink: 0;
}
.cb-glyph-numeric.cb-glyph-circular.cb-glyph-3xl-down-32 {
  font-size: 16px !important;
  flex-shrink: 0;
}

.cb-glyph-numeric.cb-glyph-3xl-down-48 {
  font-size: 24px !important;
  flex-shrink: 0;
}
.cb-glyph-numeric.cb-glyph-circular.cb-glyph-3xl-down-48 {
  font-size: 24px !important;
  flex-shrink: 0;
}

.cb-glyph-numeric.cb-glyph-3xl-down-64 {
  font-size: 32px !important;
  flex-shrink: 0;
}
.cb-glyph-numeric.cb-glyph-circular.cb-glyph-3xl-down-64 {
  font-size: 32px !important;
  flex-shrink: 0;
}

.cb-glyph-content.cb-glyph-32 {
  font-size: 32px;
}

.cb-glyph-content.cb-glyph-36 {
  font-size: 36px;
}

.cb-glyph-content.cb-glyph-48 {
  font-size: 48px;
}

@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-glyph-content.cb-glyph-xs-32 {
    font-size: 32px !important;
  }
  .cb-glyph-content.cb-glyph-xs-36 {
    font-size: 36px !important;
  }
  .cb-glyph-content.cb-glyph-xs-48 {
    font-size: 48px !important;
  }
}
@media (min-width: 1px) {
  .cb-glyph-content.cb-glyph-xs-up-32 {
    font-size: 32px !important;
  }
  .cb-glyph-content.cb-glyph-xs-up-36 {
    font-size: 36px !important;
  }
  .cb-glyph-content.cb-glyph-xs-up-48 {
    font-size: 48px !important;
  }
}
@media (max-width: 767.98px) {
  .cb-glyph-content.cb-glyph-xs-down-32 {
    font-size: 32px !important;
  }
  .cb-glyph-content.cb-glyph-xs-down-36 {
    font-size: 36px !important;
  }
  .cb-glyph-content.cb-glyph-xs-down-48 {
    font-size: 48px !important;
  }
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .cb-glyph-content.cb-glyph-sm-32 {
    font-size: 32px !important;
  }
  .cb-glyph-content.cb-glyph-sm-36 {
    font-size: 36px !important;
  }
  .cb-glyph-content.cb-glyph-sm-48 {
    font-size: 48px !important;
  }
}
@media (min-width: 768px) {
  .cb-glyph-content.cb-glyph-sm-up-32 {
    font-size: 32px !important;
  }
  .cb-glyph-content.cb-glyph-sm-up-36 {
    font-size: 36px !important;
  }
  .cb-glyph-content.cb-glyph-sm-up-48 {
    font-size: 48px !important;
  }
}
@media (max-width: 1023.98px) {
  .cb-glyph-content.cb-glyph-sm-down-32 {
    font-size: 32px !important;
  }
  .cb-glyph-content.cb-glyph-sm-down-36 {
    font-size: 36px !important;
  }
  .cb-glyph-content.cb-glyph-sm-down-48 {
    font-size: 48px !important;
  }
}
@media (min-width: 1024px) and (max-width: 1247.98px) {
  .cb-glyph-content.cb-glyph-md-32 {
    font-size: 32px !important;
  }
  .cb-glyph-content.cb-glyph-md-36 {
    font-size: 36px !important;
  }
  .cb-glyph-content.cb-glyph-md-48 {
    font-size: 48px !important;
  }
}
@media (min-width: 1024px) {
  .cb-glyph-content.cb-glyph-md-up-32 {
    font-size: 32px !important;
  }
  .cb-glyph-content.cb-glyph-md-up-36 {
    font-size: 36px !important;
  }
  .cb-glyph-content.cb-glyph-md-up-48 {
    font-size: 48px !important;
  }
}
@media (max-width: 1247.98px) {
  .cb-glyph-content.cb-glyph-md-down-32 {
    font-size: 32px !important;
  }
  .cb-glyph-content.cb-glyph-md-down-36 {
    font-size: 36px !important;
  }
  .cb-glyph-content.cb-glyph-md-down-48 {
    font-size: 48px !important;
  }
}
@media (min-width: 1248px) and (max-width: 1343.98px) {
  .cb-glyph-content.cb-glyph-lg-32 {
    font-size: 32px !important;
  }
  .cb-glyph-content.cb-glyph-lg-36 {
    font-size: 36px !important;
  }
  .cb-glyph-content.cb-glyph-lg-48 {
    font-size: 48px !important;
  }
}
@media (min-width: 1248px) {
  .cb-glyph-content.cb-glyph-lg-up-32 {
    font-size: 32px !important;
  }
  .cb-glyph-content.cb-glyph-lg-up-36 {
    font-size: 36px !important;
  }
  .cb-glyph-content.cb-glyph-lg-up-48 {
    font-size: 48px !important;
  }
}
@media (max-width: 1343.98px) {
  .cb-glyph-content.cb-glyph-lg-down-32 {
    font-size: 32px !important;
  }
  .cb-glyph-content.cb-glyph-lg-down-36 {
    font-size: 36px !important;
  }
  .cb-glyph-content.cb-glyph-lg-down-48 {
    font-size: 48px !important;
  }
}
@media (min-width: 1344px) and (max-width: 1439.98px) {
  .cb-glyph-content.cb-glyph-xl-32 {
    font-size: 32px !important;
  }
  .cb-glyph-content.cb-glyph-xl-36 {
    font-size: 36px !important;
  }
  .cb-glyph-content.cb-glyph-xl-48 {
    font-size: 48px !important;
  }
}
@media (min-width: 1344px) {
  .cb-glyph-content.cb-glyph-xl-up-32 {
    font-size: 32px !important;
  }
  .cb-glyph-content.cb-glyph-xl-up-36 {
    font-size: 36px !important;
  }
  .cb-glyph-content.cb-glyph-xl-up-48 {
    font-size: 48px !important;
  }
}
@media (max-width: 1439.98px) {
  .cb-glyph-content.cb-glyph-xl-down-32 {
    font-size: 32px !important;
  }
  .cb-glyph-content.cb-glyph-xl-down-36 {
    font-size: 36px !important;
  }
  .cb-glyph-content.cb-glyph-xl-down-48 {
    font-size: 48px !important;
  }
}
@media (min-width: 1440px) and (max-width: 1727.98px) {
  .cb-glyph-content.cb-glyph-2xl-32 {
    font-size: 32px !important;
  }
  .cb-glyph-content.cb-glyph-2xl-36 {
    font-size: 36px !important;
  }
  .cb-glyph-content.cb-glyph-2xl-48 {
    font-size: 48px !important;
  }
}
@media (min-width: 1440px) {
  .cb-glyph-content.cb-glyph-2xl-up-32 {
    font-size: 32px !important;
  }
  .cb-glyph-content.cb-glyph-2xl-up-36 {
    font-size: 36px !important;
  }
  .cb-glyph-content.cb-glyph-2xl-up-48 {
    font-size: 48px !important;
  }
}
@media (max-width: 1727.98px) {
  .cb-glyph-content.cb-glyph-2xl-down-32 {
    font-size: 32px !important;
  }
  .cb-glyph-content.cb-glyph-2xl-down-36 {
    font-size: 36px !important;
  }
  .cb-glyph-content.cb-glyph-2xl-down-48 {
    font-size: 48px !important;
  }
}
@media (min-width: 1728px) {
  .cb-glyph-content.cb-glyph-3xl-32 {
    font-size: 32px !important;
  }
  .cb-glyph-content.cb-glyph-3xl-36 {
    font-size: 36px !important;
  }
  .cb-glyph-content.cb-glyph-3xl-48 {
    font-size: 48px !important;
  }
}
@media (min-width: 1728px) {
  .cb-glyph-content.cb-glyph-3xl-up-32 {
    font-size: 32px !important;
  }
  .cb-glyph-content.cb-glyph-3xl-up-36 {
    font-size: 36px !important;
  }
  .cb-glyph-content.cb-glyph-3xl-up-48 {
    font-size: 48px !important;
  }
}
.cb-glyph-content.cb-glyph-3xl-down-32 {
  font-size: 32px !important;
}

.cb-glyph-content.cb-glyph-3xl-down-36 {
  font-size: 36px !important;
}

.cb-glyph-content.cb-glyph-3xl-down-48 {
  font-size: 48px !important;
}

.cb-glyph-content-multi.cb-glyph-64 {
  font-size: 64px;
  flex-shrink: 0;
}

.cb-glyph-content-multi.cb-glyph-72 {
  font-size: 72px;
  flex-shrink: 0;
}

.cb-glyph-content-multi.cb-glyph-96 {
  font-size: 96px;
  flex-shrink: 0;
}

@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-glyph-content-multi.cb-glyph-xs-64 {
    font-size: 64px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-xs-72 {
    font-size: 72px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-xs-96 {
    font-size: 96px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1px) {
  .cb-glyph-content-multi.cb-glyph-xs-up-64 {
    font-size: 64px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-xs-up-72 {
    font-size: 72px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-xs-up-96 {
    font-size: 96px !important;
    flex-shrink: 0;
  }
}
@media (max-width: 767.98px) {
  .cb-glyph-content-multi.cb-glyph-xs-down-64 {
    font-size: 64px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-xs-down-72 {
    font-size: 72px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-xs-down-96 {
    font-size: 96px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .cb-glyph-content-multi.cb-glyph-sm-64 {
    font-size: 64px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-sm-72 {
    font-size: 72px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-sm-96 {
    font-size: 96px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 768px) {
  .cb-glyph-content-multi.cb-glyph-sm-up-64 {
    font-size: 64px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-sm-up-72 {
    font-size: 72px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-sm-up-96 {
    font-size: 96px !important;
    flex-shrink: 0;
  }
}
@media (max-width: 1023.98px) {
  .cb-glyph-content-multi.cb-glyph-sm-down-64 {
    font-size: 64px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-sm-down-72 {
    font-size: 72px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-sm-down-96 {
    font-size: 96px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1024px) and (max-width: 1247.98px) {
  .cb-glyph-content-multi.cb-glyph-md-64 {
    font-size: 64px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-md-72 {
    font-size: 72px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-md-96 {
    font-size: 96px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1024px) {
  .cb-glyph-content-multi.cb-glyph-md-up-64 {
    font-size: 64px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-md-up-72 {
    font-size: 72px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-md-up-96 {
    font-size: 96px !important;
    flex-shrink: 0;
  }
}
@media (max-width: 1247.98px) {
  .cb-glyph-content-multi.cb-glyph-md-down-64 {
    font-size: 64px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-md-down-72 {
    font-size: 72px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-md-down-96 {
    font-size: 96px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1248px) and (max-width: 1343.98px) {
  .cb-glyph-content-multi.cb-glyph-lg-64 {
    font-size: 64px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-lg-72 {
    font-size: 72px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-lg-96 {
    font-size: 96px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1248px) {
  .cb-glyph-content-multi.cb-glyph-lg-up-64 {
    font-size: 64px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-lg-up-72 {
    font-size: 72px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-lg-up-96 {
    font-size: 96px !important;
    flex-shrink: 0;
  }
}
@media (max-width: 1343.98px) {
  .cb-glyph-content-multi.cb-glyph-lg-down-64 {
    font-size: 64px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-lg-down-72 {
    font-size: 72px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-lg-down-96 {
    font-size: 96px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1344px) and (max-width: 1439.98px) {
  .cb-glyph-content-multi.cb-glyph-xl-64 {
    font-size: 64px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-xl-72 {
    font-size: 72px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-xl-96 {
    font-size: 96px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1344px) {
  .cb-glyph-content-multi.cb-glyph-xl-up-64 {
    font-size: 64px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-xl-up-72 {
    font-size: 72px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-xl-up-96 {
    font-size: 96px !important;
    flex-shrink: 0;
  }
}
@media (max-width: 1439.98px) {
  .cb-glyph-content-multi.cb-glyph-xl-down-64 {
    font-size: 64px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-xl-down-72 {
    font-size: 72px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-xl-down-96 {
    font-size: 96px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1440px) and (max-width: 1727.98px) {
  .cb-glyph-content-multi.cb-glyph-2xl-64 {
    font-size: 64px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-2xl-72 {
    font-size: 72px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-2xl-96 {
    font-size: 96px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1440px) {
  .cb-glyph-content-multi.cb-glyph-2xl-up-64 {
    font-size: 64px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-2xl-up-72 {
    font-size: 72px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-2xl-up-96 {
    font-size: 96px !important;
    flex-shrink: 0;
  }
}
@media (max-width: 1727.98px) {
  .cb-glyph-content-multi.cb-glyph-2xl-down-64 {
    font-size: 64px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-2xl-down-72 {
    font-size: 72px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-2xl-down-96 {
    font-size: 96px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1728px) {
  .cb-glyph-content-multi.cb-glyph-3xl-64 {
    font-size: 64px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-3xl-72 {
    font-size: 72px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-3xl-96 {
    font-size: 96px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1728px) {
  .cb-glyph-content-multi.cb-glyph-3xl-up-64 {
    font-size: 64px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-3xl-up-72 {
    font-size: 72px !important;
    flex-shrink: 0;
  }
  .cb-glyph-content-multi.cb-glyph-3xl-up-96 {
    font-size: 96px !important;
    flex-shrink: 0;
  }
}
.cb-glyph-content-multi.cb-glyph-3xl-down-64 {
  font-size: 64px !important;
  flex-shrink: 0;
}

.cb-glyph-content-multi.cb-glyph-3xl-down-72 {
  font-size: 72px !important;
  flex-shrink: 0;
}

.cb-glyph-content-multi.cb-glyph-3xl-down-96 {
  font-size: 96px !important;
  flex-shrink: 0;
}

.cb-glyph.cb-white-color.cb-glyph-circular::after, .cb-glyph.cb-white-color.cb-glyph-avatar::after {
  background-color: #ffffff;
}

.cb-glyph-numeric.cb-white-color {
  border-color: #ffffff;
}
.cb-glyph-numeric.cb-white-color.cb-glyph-circular {
  color: #ffffff !important;
  border-color: #ffffff;
  background-color: #ffffff;
}

.cb-glyph.cb-black1-color.cb-glyph-circular::after, .cb-glyph.cb-black1-color.cb-glyph-avatar::after {
  background-color: #1e1e1e;
}

.cb-glyph-numeric.cb-black1-color {
  border-color: #1e1e1e;
}
.cb-glyph-numeric.cb-black1-color.cb-glyph-circular {
  color: #ffffff !important;
  border-color: #1e1e1e;
  background-color: #1e1e1e;
}

.cb-glyph.cb-black2-color.cb-glyph-circular::after, .cb-glyph.cb-black2-color.cb-glyph-avatar::after {
  background-color: #151515;
}

.cb-glyph-numeric.cb-black2-color {
  border-color: #151515;
}
.cb-glyph-numeric.cb-black2-color.cb-glyph-circular {
  color: #ffffff !important;
  border-color: #151515;
  background-color: #151515;
}

.cb-glyph.cb-blue1-color.cb-glyph-circular::after, .cb-glyph.cb-blue1-color.cb-glyph-avatar::after {
  background-color: #006298;
}

.cb-glyph-numeric.cb-blue1-color {
  border-color: #006298;
}
.cb-glyph-numeric.cb-blue1-color.cb-glyph-circular {
  color: #ffffff !important;
  border-color: #006298;
  background-color: #006298;
}

.cb-glyph.cb-blue2-color.cb-glyph-circular::after, .cb-glyph.cb-blue2-color.cb-glyph-avatar::after {
  background-color: #0077c8;
}

.cb-glyph-numeric.cb-blue2-color {
  border-color: #0077c8;
}
.cb-glyph-numeric.cb-blue2-color.cb-glyph-circular {
  color: #ffffff !important;
  border-color: #0077c8;
  background-color: #0077c8;
}

.cb-glyph.cb-blue3-color.cb-glyph-circular::after, .cb-glyph.cb-blue3-color.cb-glyph-avatar::after {
  background-color: #009cde;
}

.cb-glyph-numeric.cb-blue3-color {
  border-color: #009cde;
}
.cb-glyph-numeric.cb-blue3-color.cb-glyph-circular {
  color: #ffffff !important;
  border-color: #009cde;
  background-color: #009cde;
}

.cb-glyph.cb-blue4-color.cb-glyph-circular::after, .cb-glyph.cb-blue4-color.cb-glyph-avatar::after {
  background-color: #71c5e8;
}

.cb-glyph-numeric.cb-blue4-color {
  border-color: #71c5e8;
}
.cb-glyph-numeric.cb-blue4-color.cb-glyph-circular {
  color: #ffffff !important;
  border-color: #71c5e8;
  background-color: #71c5e8;
}

.cb-glyph.cb-blue5-color.cb-glyph-circular::after, .cb-glyph.cb-blue5-color.cb-glyph-avatar::after {
  background-color: #324dc7;
}

.cb-glyph-numeric.cb-blue5-color {
  border-color: #324dc7;
}
.cb-glyph-numeric.cb-blue5-color.cb-glyph-circular {
  color: #ffffff !important;
  border-color: #324dc7;
  background-color: #324dc7;
}

.cb-glyph.cb-gray1-color.cb-glyph-circular::after, .cb-glyph.cb-gray1-color.cb-glyph-avatar::after {
  background-color: #505050;
}

.cb-glyph-numeric.cb-gray1-color {
  border-color: #505050;
}
.cb-glyph-numeric.cb-gray1-color.cb-glyph-circular {
  color: #ffffff !important;
  border-color: #505050;
  background-color: #505050;
}

.cb-glyph.cb-gray2-color.cb-glyph-circular::after, .cb-glyph.cb-gray2-color.cb-glyph-avatar::after {
  background-color: #888888;
}

.cb-glyph-numeric.cb-gray2-color {
  border-color: #888888;
}
.cb-glyph-numeric.cb-gray2-color.cb-glyph-circular {
  color: #ffffff !important;
  border-color: #888888;
  background-color: #888888;
}

.cb-glyph.cb-gray3-color.cb-glyph-circular::after, .cb-glyph.cb-gray3-color.cb-glyph-avatar::after {
  background-color: #b2b2b2;
}

.cb-glyph-numeric.cb-gray3-color {
  border-color: #b2b2b2;
}
.cb-glyph-numeric.cb-gray3-color.cb-glyph-circular {
  color: #ffffff !important;
  border-color: #b2b2b2;
  background-color: #b2b2b2;
}

.cb-glyph.cb-gray4-color.cb-glyph-circular::after, .cb-glyph.cb-gray4-color.cb-glyph-avatar::after {
  background-color: #d9d9d9;
}

.cb-glyph-numeric.cb-gray4-color {
  border-color: #d9d9d9;
}
.cb-glyph-numeric.cb-gray4-color.cb-glyph-circular {
  color: #ffffff !important;
  border-color: #d9d9d9;
  background-color: #d9d9d9;
}

.cb-glyph.cb-gray5-color.cb-glyph-circular::after, .cb-glyph.cb-gray5-color.cb-glyph-avatar::after {
  background-color: #f0f0f0;
}

.cb-glyph-numeric.cb-gray5-color {
  border-color: #f0f0f0;
}
.cb-glyph-numeric.cb-gray5-color.cb-glyph-circular {
  color: #ffffff !important;
  border-color: #f0f0f0;
  background-color: #f0f0f0;
}

.cb-glyph.cb-yellow1-color.cb-glyph-circular::after, .cb-glyph.cb-yellow1-color.cb-glyph-avatar::after {
  background-color: #fedb00;
}

.cb-glyph-numeric.cb-yellow1-color {
  border-color: #fedb00;
}
.cb-glyph-numeric.cb-yellow1-color.cb-glyph-circular {
  color: #ffffff !important;
  border-color: #fedb00;
  background-color: #fedb00;
}

.cb-glyph.cb-orange1-color.cb-glyph-circular::after, .cb-glyph.cb-orange1-color.cb-glyph-avatar::after {
  background-color: #e57200;
}

.cb-glyph-numeric.cb-orange1-color {
  border-color: #e57200;
}
.cb-glyph-numeric.cb-orange1-color.cb-glyph-circular {
  color: #ffffff !important;
  border-color: #e57200;
  background-color: #e57200;
}

.cb-glyph.cb-red1-color.cb-glyph-circular::after, .cb-glyph.cb-red1-color.cb-glyph-avatar::after {
  background-color: #ab2334;
}

.cb-glyph-numeric.cb-red1-color {
  border-color: #ab2334;
}
.cb-glyph-numeric.cb-red1-color.cb-glyph-circular {
  color: #ffffff !important;
  border-color: #ab2334;
  background-color: #ab2334;
}

.cb-glyph.cb-purple1-color.cb-glyph-circular::after, .cb-glyph.cb-purple1-color.cb-glyph-avatar::after {
  background-color: #702f8a;
}

.cb-glyph-numeric.cb-purple1-color {
  border-color: #702f8a;
}
.cb-glyph-numeric.cb-purple1-color.cb-glyph-circular {
  color: #ffffff !important;
  border-color: #702f8a;
  background-color: #702f8a;
}

.cb-glyph.cb-purple2-color.cb-glyph-circular::after, .cb-glyph.cb-purple2-color.cb-glyph-avatar::after {
  background-color: #a05eb5;
}

.cb-glyph-numeric.cb-purple2-color {
  border-color: #a05eb5;
}
.cb-glyph-numeric.cb-purple2-color.cb-glyph-circular {
  color: #ffffff !important;
  border-color: #a05eb5;
  background-color: #a05eb5;
}

.cb-glyph.cb-green1-color.cb-glyph-circular::after, .cb-glyph.cb-green1-color.cb-glyph-avatar::after {
  background-color: #3a913f;
}

.cb-glyph-numeric.cb-green1-color {
  border-color: #3a913f;
}
.cb-glyph-numeric.cb-green1-color.cb-glyph-circular {
  color: #ffffff !important;
  border-color: #3a913f;
  background-color: #3a913f;
}

.cb-glyph.cb-green2-color.cb-glyph-circular::after, .cb-glyph.cb-green2-color.cb-glyph-avatar::after {
  background-color: #6cc24a;
}

.cb-glyph-numeric.cb-green2-color {
  border-color: #6cc24a;
}
.cb-glyph-numeric.cb-green2-color.cb-glyph-circular {
  color: #ffffff !important;
  border-color: #6cc24a;
  background-color: #6cc24a;
}

.cb-glyph.cb-green3-color.cb-glyph-circular::after, .cb-glyph.cb-green3-color.cb-glyph-avatar::after {
  background-color: #1d7846;
}

.cb-glyph-numeric.cb-green3-color {
  border-color: #1d7846;
}
.cb-glyph-numeric.cb-green3-color.cb-glyph-circular {
  color: #ffffff !important;
  border-color: #1d7846;
  background-color: #1d7846;
}

@media print {
  .cb-glyph-numeric {
    border-color: #000 !important;
    color: #000 !important;
  }
  .cb-glyph-numeric.cb-glyph-circular {
    color: #000 !important;
  }
  .cb-glyph-numeric.cb-glyph-circular[class*=cb-white] {
    color: #000 !important;
  }
  .cb-glyph-numeric.cb-glyph-circular[class*=cb-black1] {
    color: #000 !important;
  }
  .cb-glyph-numeric.cb-glyph-circular[class*=cb-black2] {
    color: #000 !important;
  }
  .cb-glyph-numeric.cb-glyph-circular[class*=cb-blue1] {
    color: #000 !important;
  }
  .cb-glyph-numeric.cb-glyph-circular[class*=cb-blue2] {
    color: #000 !important;
  }
  .cb-glyph-numeric.cb-glyph-circular[class*=cb-blue3] {
    color: #000 !important;
  }
  .cb-glyph-numeric.cb-glyph-circular[class*=cb-blue4] {
    color: #000 !important;
  }
  .cb-glyph-numeric.cb-glyph-circular[class*=cb-blue5] {
    color: #000 !important;
  }
  .cb-glyph-numeric.cb-glyph-circular[class*=cb-gray1] {
    color: #000 !important;
  }
  .cb-glyph-numeric.cb-glyph-circular[class*=cb-gray2] {
    color: #000 !important;
  }
  .cb-glyph-numeric.cb-glyph-circular[class*=cb-gray3] {
    color: #000 !important;
  }
  .cb-glyph-numeric.cb-glyph-circular[class*=cb-gray4] {
    color: #000 !important;
  }
  .cb-glyph-numeric.cb-glyph-circular[class*=cb-gray5] {
    color: #000 !important;
  }
  .cb-glyph-numeric.cb-glyph-circular[class*=cb-yellow1] {
    color: #000 !important;
  }
  .cb-glyph-numeric.cb-glyph-circular[class*=cb-orange1] {
    color: #000 !important;
  }
  .cb-glyph-numeric.cb-glyph-circular[class*=cb-red1] {
    color: #000 !important;
  }
  .cb-glyph-numeric.cb-glyph-circular[class*=cb-purple1] {
    color: #000 !important;
  }
  .cb-glyph-numeric.cb-glyph-circular[class*=cb-purple2] {
    color: #000 !important;
  }
  .cb-glyph-numeric.cb-glyph-circular[class*=cb-green1] {
    color: #000 !important;
  }
  .cb-glyph-numeric.cb-glyph-circular[class*=cb-green2] {
    color: #000 !important;
  }
  .cb-glyph-numeric.cb-glyph-circular[class*=cb-green3] {
    color: #000 !important;
  }
  .cb-glyph-numeric.cb-glyph-circular::before {
    color: #000 !important;
  }
}
.cb-print .cb-glyph-numeric {
  border-color: #000 !important;
  color: #000 !important;
}
.cb-print .cb-glyph-numeric.cb-glyph-circular {
  color: #000 !important;
}
.cb-print .cb-glyph-numeric.cb-glyph-circular[class*=cb-white] {
  color: #000 !important;
}
.cb-print .cb-glyph-numeric.cb-glyph-circular[class*=cb-black1] {
  color: #000 !important;
}
.cb-print .cb-glyph-numeric.cb-glyph-circular[class*=cb-black2] {
  color: #000 !important;
}
.cb-print .cb-glyph-numeric.cb-glyph-circular[class*=cb-blue1] {
  color: #000 !important;
}
.cb-print .cb-glyph-numeric.cb-glyph-circular[class*=cb-blue2] {
  color: #000 !important;
}
.cb-print .cb-glyph-numeric.cb-glyph-circular[class*=cb-blue3] {
  color: #000 !important;
}
.cb-print .cb-glyph-numeric.cb-glyph-circular[class*=cb-blue4] {
  color: #000 !important;
}
.cb-print .cb-glyph-numeric.cb-glyph-circular[class*=cb-blue5] {
  color: #000 !important;
}
.cb-print .cb-glyph-numeric.cb-glyph-circular[class*=cb-gray1] {
  color: #000 !important;
}
.cb-print .cb-glyph-numeric.cb-glyph-circular[class*=cb-gray2] {
  color: #000 !important;
}
.cb-print .cb-glyph-numeric.cb-glyph-circular[class*=cb-gray3] {
  color: #000 !important;
}
.cb-print .cb-glyph-numeric.cb-glyph-circular[class*=cb-gray4] {
  color: #000 !important;
}
.cb-print .cb-glyph-numeric.cb-glyph-circular[class*=cb-gray5] {
  color: #000 !important;
}
.cb-print .cb-glyph-numeric.cb-glyph-circular[class*=cb-yellow1] {
  color: #000 !important;
}
.cb-print .cb-glyph-numeric.cb-glyph-circular[class*=cb-orange1] {
  color: #000 !important;
}
.cb-print .cb-glyph-numeric.cb-glyph-circular[class*=cb-red1] {
  color: #000 !important;
}
.cb-print .cb-glyph-numeric.cb-glyph-circular[class*=cb-purple1] {
  color: #000 !important;
}
.cb-print .cb-glyph-numeric.cb-glyph-circular[class*=cb-purple2] {
  color: #000 !important;
}
.cb-print .cb-glyph-numeric.cb-glyph-circular[class*=cb-green1] {
  color: #000 !important;
}
.cb-print .cb-glyph-numeric.cb-glyph-circular[class*=cb-green2] {
  color: #000 !important;
}
.cb-print .cb-glyph-numeric.cb-glyph-circular[class*=cb-green3] {
  color: #000 !important;
}
.cb-print .cb-glyph-numeric.cb-glyph-circular::before {
  color: #000 !important;
}

.cb-glyph-content {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  font-size: 2rem;
  line-height: 1em;
  font-family: CB Icons Multi;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.cb-glyph-content::before, .cb-glyph-content::after {
  position: absolute;
  top: -50%;
  left: -50%;
  font-size: 2em;
  width: 0.5em;
  height: 0.5em;
}
.cb-glyph-content.cb-accessible::before {
  content: "\e900";
}
.cb-glyph-content.cb-accessible::after {
  content: "\e97c";
}
.cb-glyph-content.cb-app::before {
  content: "\e901";
}
.cb-glyph-content.cb-app::after {
  content: "\e97d";
}
.cb-glyph-content.cb-bag::before {
  content: "\e902";
}
.cb-glyph-content.cb-bag::after {
  content: "\e97e";
}
.cb-glyph-content.cb-barchart::before {
  content: "\e903";
}
.cb-glyph-content.cb-barchart::after {
  content: "\e97f";
}
.cb-glyph-content.cb-batch-download::before {
  content: "\e904";
}
.cb-glyph-content.cb-batch-download::after {
  content: "\e980";
}
.cb-glyph-content.cb-batch-upload::before {
  content: "\e905";
}
.cb-glyph-content.cb-batch-upload::after {
  content: "\e981";
}
.cb-glyph-content.cb-bell::before {
  content: "\e906";
}
.cb-glyph-content.cb-bell::after {
  content: "\e982";
}
.cb-glyph-content.cb-book::before {
  content: "\e907";
}
.cb-glyph-content.cb-book::after {
  content: "\e983";
}
.cb-glyph-content.cb-bullet-list::before {
  content: "\e908";
}
.cb-glyph-content.cb-bullet-list::after {
  content: "\e984";
}
.cb-glyph-content.cb-bullet-list-doc::before {
  content: "\e909";
}
.cb-glyph-content.cb-bullet-list-doc::after {
  content: "\e985";
}
.cb-glyph-content.cb-calculator::before {
  content: "\e90a";
}
.cb-glyph-content.cb-calculator::after {
  content: "\e986";
}
.cb-glyph-content.cb-calendar-empty::before {
  content: "\e90b";
}
.cb-glyph-content.cb-calendar-empty::after {
  content: "\e987";
}
.cb-glyph-content.cb-calendar-full::before {
  content: "\e90c";
}
.cb-glyph-content.cb-calendar-full::after {
  content: "\e988";
}
.cb-glyph-content.cb-cart::before {
  content: "\e90d";
}
.cb-glyph-content.cb-cart::after {
  content: "\e989";
}
.cb-glyph-content.cb-chat::before {
  content: "\e90e";
}
.cb-glyph-content.cb-chat::after {
  content: "\e98a";
}
.cb-glyph-content.cb-check::before {
  content: "\e910";
}
.cb-glyph-content.cb-check::after {
  content: "\e98b";
}
.cb-glyph-content.cb-check-list::before {
  content: "\e911";
}
.cb-glyph-content.cb-check-list::after {
  content: "\e98c";
}
.cb-glyph-content.cb-check-list-doc::before {
  content: "\e912";
}
.cb-glyph-content.cb-check-list-doc::after {
  content: "\e98d";
}
.cb-glyph-content.cb-college-reputation::before {
  content: "\e914";
}
.cb-glyph-content.cb-college-reputation::after {
  content: "\e98e";
}
.cb-glyph-content.cb-compose::before {
  content: "\e915";
}
.cb-glyph-content.cb-compose::after {
  content: "\e98f";
}
.cb-glyph-content.cb-computer::before {
  content: "\e916";
}
.cb-glyph-content.cb-computer::after {
  content: "\e990";
}
.cb-glyph-content.cb-credit-card::before {
  content: "\e917";
}
.cb-glyph-content.cb-credit-card::after {
  content: "\e991";
}
.cb-glyph-content.cb-dbl-left::before {
  content: "\e91a";
}
.cb-glyph-content.cb-dbl-left::after {
  content: "\e994";
}
.cb-glyph-content.cb-dbl-right::before {
  content: "\e91b";
}
.cb-glyph-content.cb-dbl-right::after {
  content: "\e995";
}
.cb-glyph-content.cb-dollar::before {
  content: "\e91d";
}
.cb-glyph-content.cb-dollar::after {
  content: "\e997";
}
.cb-glyph-content.cb-download::before {
  content: "\e91f";
}
.cb-glyph-content.cb-download::after {
  content: "\e999";
}
.cb-glyph-content.cb-educator::before {
  content: "\e920";
}
.cb-glyph-content.cb-educator::after {
  content: "\e99a";
}
.cb-glyph-content.cb-error::before {
  content: "\e922";
}
.cb-glyph-content.cb-everyday-words::before {
  content: "\e924";
}
.cb-glyph-content.cb-everyday-words::after {
  content: "\e99c";
}
.cb-glyph-content.cb-expand-window::before {
  content: "\e926";
}
.cb-glyph-content.cb-expand-window::after {
  content: "\e99e";
}
.cb-glyph-content.cb-fax::before {
  content: "\e929";
}
.cb-glyph-content.cb-fax::after {
  content: "\e9a0";
}
.cb-glyph-content.cb-full-length-practice::before {
  content: "\e92b";
}
.cb-glyph-content.cb-full-length-practice::after {
  content: "\e9a1";
}
.cb-glyph-content.cb-globe::before {
  content: "\e967";
}
.cb-glyph-content.cb-globe::after {
  content: "\e965";
}
.cb-glyph-content.cb-graduate-pro-school::before {
  content: "\e92d";
}
.cb-glyph-content.cb-graduate-pro-school::after {
  content: "\e9a3";
}
.cb-glyph-content.cb-graduation::before {
  content: "\e92e";
}
.cb-glyph-content.cb-graduation::after {
  content: "\e9a4";
}
.cb-glyph-content.cb-home::before {
  content: "\e92f";
}
.cb-glyph-content.cb-home::after {
  content: "\e9a5";
}
.cb-glyph-content.cb-information::before {
  content: "\e930";
}
.cb-glyph-content.cb-information::after {
  content: "\e9a6";
}
.cb-glyph-content.cb-instagram::before {
  content: "\e931";
}
.cb-glyph-content.cb-facebook::before {
  content: "\e928";
}
.cb-glyph-content.cb-youtube::before {
  content: "\e97b";
}
.cb-glyph-content.cb-linkedin::before {
  content: "\e934";
}
.cb-glyph-content.cb-twitter::before {
  content: "\e972";
}
.cb-glyph-content.cb-light-bulb::before {
  content: "\e933";
}
.cb-glyph-content.cb-light-bulb::after {
  content: "\e9a8";
}
.cb-glyph-content.cb-list::before {
  content: "\e935";
}
.cb-glyph-content.cb-list::after {
  content: "\e9a9";
}
.cb-glyph-content.cb-local-menu::before {
  content: "\e936";
}
.cb-glyph-content.cb-local-menu::after {
  content: "\e9aa";
}
.cb-glyph-content.cb-location::before {
  content: "\e937";
}
.cb-glyph-content.cb-location::after {
  content: "\e9ab";
}
.cb-glyph-content.cb-lock::before {
  content: "\e938";
}
.cb-glyph-content.cb-lock::after {
  content: "\e9ac";
}
.cb-glyph-content.cb-lock-open::before {
  content: "\e939";
}
.cb-glyph-content.cb-lock-open::after {
  content: "\e9ad";
}
.cb-glyph-content.cb-mail::before {
  content: "\e93a";
}
.cb-glyph-content.cb-mail::after {
  content: "\e9ae";
}
.cb-glyph-content.cb-matrix::before {
  content: "\e93b";
}
.cb-glyph-content.cb-matrix::after {
  content: "\e9af";
}
.cb-glyph-content.cb-megaphone::before {
  content: "\e93c";
}
.cb-glyph-content.cb-megaphone::after {
  content: "\e9b0";
}
.cb-glyph-content.cb-menu::before {
  content: "\e93d";
}
.cb-glyph-content.cb-menu::after {
  content: "\e9b1";
}
.cb-glyph-content.cb-minus::before {
  content: "\e93e";
}
.cb-glyph-content.cb-minus::after {
  content: "\e9b2";
}
.cb-glyph-content.cb-nav-links::before {
  content: "\e93f";
}
.cb-glyph-content.cb-nav-links::after {
  content: "\e9b3";
}
.cb-glyph-content.cb-new-doc::before {
  content: "\e941";
}
.cb-glyph-content.cb-new-doc::after {
  content: "\e9b5";
}
.cb-glyph-content.cb-new-window::before {
  content: "\e942";
}
.cb-glyph-content.cb-new-window::after {
  content: "\e9b6";
}
.cb-glyph-content.cb-note::before {
  content: "\e947";
}
.cb-glyph-content.cb-note::after {
  content: "\e9b9";
}
.cb-glyph-content.cb-online-tool::before {
  content: "\e948";
}
.cb-glyph-content.cb-online-tool::after {
  content: "\e9ba";
}
.cb-glyph-content.cb-pending::before {
  content: "\e949";
}
.cb-glyph-content.cb-pending::after {
  content: "\e9bb";
}
.cb-glyph-content.cb-phone::before {
  content: "\e94a";
}
.cb-glyph-content.cb-phone::after {
  content: "\e9bc";
}
.cb-glyph-content.cb-phone-alt::before {
  content: "\e94b";
}
.cb-glyph-content.cb-phone-alt::after {
  content: "\e9bd";
}
.cb-glyph-content.cb-plus::before {
  content: "\e94c";
}
.cb-glyph-content.cb-plus::after {
  content: "\e9be";
}
.cb-glyph-content.cb-practice::before {
  content: "\e94d";
}
.cb-glyph-content.cb-practice::after {
  content: "\e9bf";
}
.cb-glyph-content.cb-practice-test::before {
  content: "\e94e";
}
.cb-glyph-content.cb-practice-test::after {
  content: "\e9c0";
}
.cb-glyph-content.cb-presentation::before {
  content: "\e94f";
}
.cb-glyph-content.cb-presentation::after {
  content: "\e9c1";
}
.cb-glyph-content.cb-print::before {
  content: "\e950";
}
.cb-glyph-content.cb-print::after {
  content: "\e9c2";
}
.cb-glyph-content.cb-problem-feedback::before {
  content: "\e951";
}
.cb-glyph-content.cb-problem-feedback::after {
  content: "\e9c3";
}
.cb-glyph-content.cb-raided-pencil::before {
  content: "\e955";
}
.cb-glyph-content.cb-raided-pencil::after {
  content: "\e9c5";
}
.cb-glyph-content.cb-resources::before {
  content: "\e956";
}
.cb-glyph-content.cb-resources::after {
  content: "\e9c6";
}
.cb-glyph-content.cb-rupee::before {
  content: "\e958";
}
.cb-glyph-content.cb-rupee::after {
  content: "\e9c8";
}
.cb-glyph-content.cb-save-date::before {
  content: "\e959";
}
.cb-glyph-content.cb-save-date::after {
  content: "\e9c9";
}
.cb-glyph-content.cb-science::before {
  content: "\e95d";
}
.cb-glyph-content.cb-science::after {
  content: "\e9cc";
}
.cb-glyph-content.cb-search::before {
  content: "\e95e";
}
.cb-glyph-content.cb-search::after {
  content: "\e9cd";
}
.cb-glyph-content.cb-series::before {
  content: "\e95f";
}
.cb-glyph-content.cb-series::after {
  content: "\e9ce";
}
.cb-glyph-content.cb-settings::before {
  content: "\e960";
}
.cb-glyph-content.cb-settings::after {
  content: "\e9cf";
}
.cb-glyph-content.cb-share::before {
  content: "\e961";
}
.cb-glyph-content.cb-share::after {
  content: "\e9d0";
}
.cb-glyph-content.cb-share-alt::before {
  content: "\e962";
}
.cb-glyph-content.cb-share-alt::after {
  content: "\e9d1";
}
.cb-glyph-content.cb-sound::before {
  content: "\e966";
}
.cb-glyph-content.cb-sound::after {
  content: "\e9d4";
}
.cb-glyph-content.cb-special-edition::before {
  content: "\e969";
}
.cb-glyph-content.cb-special-edition::after {
  content: "\e9d5";
}
.cb-glyph-content.cb-tag::before {
  content: "\e96b";
}
.cb-glyph-content.cb-tag::after {
  content: "\e9d6";
}
.cb-glyph-content.cb-task-complete::before {
  content: "\e96d";
}
.cb-glyph-content.cb-task-complete::after {
  content: "\e9d8";
}
.cb-glyph-content.cb-teacher-edition::before {
  content: "\e96e";
}
.cb-glyph-content.cb-teacher-edition::after {
  content: "\e9d9";
}
.cb-glyph-content.cb-team-leader::before {
  content: "\e96f";
}
.cb-glyph-content.cb-team-leader::after {
  content: "\e9da";
}
.cb-glyph-content.cb-test-scored::before {
  content: "\e970";
}
.cb-glyph-content.cb-test-scored::after {
  content: "\e9db";
}
.cb-glyph-content.cb-tuition-financial-aid::before {
  content: "\e971";
}
.cb-glyph-content.cb-tuition-financial-aid::after {
  content: "\e9dc";
}
.cb-glyph-content.cb-upload::before {
  content: "\e974";
}
.cb-glyph-content.cb-upload::after {
  content: "\e9de";
}
.cb-glyph-content.cb-user::before {
  content: "\e975";
}
.cb-glyph-content.cb-video-lesson::before {
  content: "\e976";
}
.cb-glyph-content.cb-video-lesson::after {
  content: "\e9df";
}
.cb-glyph-content.cb-window::before {
  content: "\e978";
}
.cb-glyph-content.cb-work-together::before {
  content: "\e979";
}
.cb-glyph-content.cb-work-together::after {
  content: "\e9e0";
}
.cb-glyph-content.cb-quote-left::before {
  content: "\e913";
}
.cb-glyph-content.cb-quote-left::after {
  content: "\e91c";
}
.cb-glyph-content.cb-quote-right::before {
  content: "\e91e";
}
.cb-glyph-content.cb-quote-right::after {
  content: "\e919";
}
.cb-glyph-content.cb-add-user::before {
  content: "\e9e2";
}
.cb-glyph-content.cb-add-user::after {
  content: "\e9ec";
}
.cb-glyph-content.cb-dollars::before {
  content: "\e9e3";
}
.cb-glyph-content.cb-dollars::after {
  content: "\e9ed";
}
.cb-glyph-content.cb-essential-math::before {
  content: "\e9e4";
}
.cb-glyph-content.cb-essential-math::after {
  content: "\e9ee";
}
.cb-glyph-content.cb-explore::before {
  content: "\e9e5";
}
.cb-glyph-content.cb-explore::after {
  content: "\e9ef";
}
.cb-glyph-content.cb-face-happy::before {
  content: "\e9e6";
}
.cb-glyph-content.cb-face-happy::after {
  content: "\e9d3";
}
.cb-glyph-content.cb-face-unhappy::before {
  content: "\e9e7";
}
.cb-glyph-content.cb-face-unhappy::after {
  content: "\e9d3";
}
.cb-glyph-content.cb-face-unsure::before {
  content: "\e9e8";
}
.cb-glyph-content.cb-face-unsure::after {
  content: "\e9d3";
}
.cb-glyph-content.cb-maths::before {
  content: "\e9ea";
}
.cb-glyph-content.cb-maths::after {
  content: "\e9f3";
}
.cb-glyph-content.cb-school::before {
  content: "\e9eb";
}
.cb-glyph-content.cb-school::after {
  content: "\e9f4";
}
.cb-glyph-content.cb-exam::before {
  content: "\e921";
}
.cb-glyph-content.cb-exam::after {
  content: "\e944";
}
.cb-glyph-content.cb-khan-academy::before {
  content: "\e90f";
}
.cb-glyph-content.cb-send-scores::before {
  content: "\e925";
}
.cb-glyph-content.cb-send-scores::after {
  content: "\e945";
}
.cb-glyph-content.cb-submit-documents::before {
  content: "\e92a";
}
.cb-glyph-content.cb-submit-documents::after {
  content: "\e946";
}
.cb-glyph-content.cb-submit-portfolio::before {
  content: "\e932";
}
.cb-glyph-content.cb-submit-portfolio::after {
  content: "\e952";
}
.cb-glyph-content.cb-play::before {
  content: "\e953";
}
.cb-glyph-content.cb-signed-in::before {
  content: "\e957";
}
.cb-glyph-content.cb-signed-in::after {
  content: "\e954";
}
.cb-glyph-content.cb-laptop::before {
  content: "\e96a";
}
.cb-glyph-content.cb-laptop-check::before {
  content: "\e96a";
}
.cb-glyph-content.cb-laptop-check::after {
  content: "\e968";
}
.cb-glyph-content.cb-glyph-sm {
  font-size: calc(.5rem * 2);
}
.cb-glyph-content.cb-glyph-lg {
  font-size: calc(.5rem * 4);
}
.cb-glyph-content.cb-glyph-xl {
  font-size: calc(.5rem * 6);
}

.cb-glyph-content-multi {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  font-size: 4rem;
  line-height: 1em;
  font-family: CB Icons Multi;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0077c8;
}
.cb-glyph-content-multi::before, .cb-glyph-content-multi::after {
  position: absolute;
  top: 0;
  left: 0;
}
.cb-glyph-content-multi.cb-accessible::before {
  content: "\e900";
}
.cb-glyph-content-multi.cb-accessible::after {
  content: "\e97c";
}
.cb-glyph-content-multi.cb-app::before {
  content: "\e901";
}
.cb-glyph-content-multi.cb-app::after {
  content: "\e97d";
}
.cb-glyph-content-multi.cb-bag::before {
  content: "\e902";
}
.cb-glyph-content-multi.cb-bag::after {
  content: "\e97e";
}
.cb-glyph-content-multi.cb-barchart::before {
  content: "\e903";
}
.cb-glyph-content-multi.cb-barchart::after {
  content: "\e97f";
}
.cb-glyph-content-multi.cb-batch-download::before {
  content: "\e904";
}
.cb-glyph-content-multi.cb-batch-download::after {
  content: "\e980";
}
.cb-glyph-content-multi.cb-batch-upload::before {
  content: "\e905";
}
.cb-glyph-content-multi.cb-batch-upload::after {
  content: "\e981";
}
.cb-glyph-content-multi.cb-bell::before {
  content: "\e906";
}
.cb-glyph-content-multi.cb-bell::after {
  content: "\e982";
}
.cb-glyph-content-multi.cb-book::before {
  content: "\e907";
}
.cb-glyph-content-multi.cb-book::after {
  content: "\e983";
}
.cb-glyph-content-multi.cb-bullet-list::before {
  content: "\e908";
}
.cb-glyph-content-multi.cb-bullet-list::after {
  content: "\e984";
}
.cb-glyph-content-multi.cb-bullet-list-doc::before {
  content: "\e909";
}
.cb-glyph-content-multi.cb-bullet-list-doc::after {
  content: "\e985";
}
.cb-glyph-content-multi.cb-calculator::before {
  content: "\e90a";
}
.cb-glyph-content-multi.cb-calculator::after {
  content: "\e986";
}
.cb-glyph-content-multi.cb-calendar-empty::before {
  content: "\e90b";
}
.cb-glyph-content-multi.cb-calendar-empty::after {
  content: "\e987";
}
.cb-glyph-content-multi.cb-calendar-full::before {
  content: "\e90c";
}
.cb-glyph-content-multi.cb-calendar-full::after {
  content: "\e988";
}
.cb-glyph-content-multi.cb-cart::before {
  content: "\e90d";
}
.cb-glyph-content-multi.cb-cart::after {
  content: "\e989";
}
.cb-glyph-content-multi.cb-chat::before {
  content: "\e90e";
}
.cb-glyph-content-multi.cb-chat::after {
  content: "\e98a";
}
.cb-glyph-content-multi.cb-check::before {
  content: "\e910";
}
.cb-glyph-content-multi.cb-check::after {
  content: "\e98b";
}
.cb-glyph-content-multi.cb-check-list::before {
  content: "\e911";
}
.cb-glyph-content-multi.cb-check-list::after {
  content: "\e98c";
}
.cb-glyph-content-multi.cb-check-list-doc::before {
  content: "\e912";
}
.cb-glyph-content-multi.cb-check-list-doc::after {
  content: "\e98d";
}
.cb-glyph-content-multi.cb-college-reputation::before {
  content: "\e914";
}
.cb-glyph-content-multi.cb-college-reputation::after {
  content: "\e98e";
}
.cb-glyph-content-multi.cb-compose::before {
  content: "\e915";
}
.cb-glyph-content-multi.cb-compose::after {
  content: "\e98f";
}
.cb-glyph-content-multi.cb-computer::before {
  content: "\e916";
}
.cb-glyph-content-multi.cb-computer::after {
  content: "\e990";
}
.cb-glyph-content-multi.cb-credit-card::before {
  content: "\e917";
}
.cb-glyph-content-multi.cb-credit-card::after {
  content: "\e991";
}
.cb-glyph-content-multi.cb-dbl-left::before {
  content: "\e91a";
}
.cb-glyph-content-multi.cb-dbl-left::after {
  content: "\e994";
}
.cb-glyph-content-multi.cb-dbl-right::before {
  content: "\e91b";
}
.cb-glyph-content-multi.cb-dbl-right::after {
  content: "\e995";
}
.cb-glyph-content-multi.cb-dollar::before {
  content: "\e91d";
}
.cb-glyph-content-multi.cb-dollar::after {
  content: "\e997";
}
.cb-glyph-content-multi.cb-download::before {
  content: "\e91f";
}
.cb-glyph-content-multi.cb-download::after {
  content: "\e999";
}
.cb-glyph-content-multi.cb-educator::before {
  content: "\e920";
}
.cb-glyph-content-multi.cb-educator::after {
  content: "\e99a";
}
.cb-glyph-content-multi.cb-error::before {
  content: "\e922";
}
.cb-glyph-content-multi.cb-everyday-words::before {
  content: "\e924";
}
.cb-glyph-content-multi.cb-everyday-words::after {
  content: "\e99c";
}
.cb-glyph-content-multi.cb-expand-window::before {
  content: "\e926";
}
.cb-glyph-content-multi.cb-expand-window::after {
  content: "\e99e";
}
.cb-glyph-content-multi.cb-fax::before {
  content: "\e929";
}
.cb-glyph-content-multi.cb-fax::after {
  content: "\e9a0";
}
.cb-glyph-content-multi.cb-full-length-practice::before {
  content: "\e92b";
}
.cb-glyph-content-multi.cb-full-length-practice::after {
  content: "\e9a1";
}
.cb-glyph-content-multi.cb-globe::before {
  content: "\e967";
}
.cb-glyph-content-multi.cb-globe::after {
  content: "\e965";
}
.cb-glyph-content-multi.cb-graduate-pro-school::before {
  content: "\e92d";
}
.cb-glyph-content-multi.cb-graduate-pro-school::after {
  content: "\e9a3";
}
.cb-glyph-content-multi.cb-graduation::before {
  content: "\e92e";
}
.cb-glyph-content-multi.cb-graduation::after {
  content: "\e9a4";
}
.cb-glyph-content-multi.cb-home::before {
  content: "\e92f";
}
.cb-glyph-content-multi.cb-home::after {
  content: "\e9a5";
}
.cb-glyph-content-multi.cb-information::before {
  content: "\e930";
}
.cb-glyph-content-multi.cb-information::after {
  content: "\e9a6";
}
.cb-glyph-content-multi.cb-instagram::before {
  content: "\e931";
}
.cb-glyph-content-multi.cb-facebook::before {
  content: "\e928";
}
.cb-glyph-content-multi.cb-youtube::before {
  content: "\e97b";
}
.cb-glyph-content-multi.cb-linkedin::before {
  content: "\e934";
}
.cb-glyph-content-multi.cb-twitter::before {
  content: "\e972";
}
.cb-glyph-content-multi.cb-light-bulb::before {
  content: "\e933";
}
.cb-glyph-content-multi.cb-light-bulb::after {
  content: "\e9a8";
}
.cb-glyph-content-multi.cb-list::before {
  content: "\e935";
}
.cb-glyph-content-multi.cb-list::after {
  content: "\e9a9";
}
.cb-glyph-content-multi.cb-local-menu::before {
  content: "\e936";
}
.cb-glyph-content-multi.cb-local-menu::after {
  content: "\e9aa";
}
.cb-glyph-content-multi.cb-location::before {
  content: "\e937";
}
.cb-glyph-content-multi.cb-location::after {
  content: "\e9ab";
}
.cb-glyph-content-multi.cb-lock::before {
  content: "\e938";
}
.cb-glyph-content-multi.cb-lock::after {
  content: "\e9ac";
}
.cb-glyph-content-multi.cb-lock-open::before {
  content: "\e939";
}
.cb-glyph-content-multi.cb-lock-open::after {
  content: "\e9ad";
}
.cb-glyph-content-multi.cb-mail::before {
  content: "\e93a";
}
.cb-glyph-content-multi.cb-mail::after {
  content: "\e9ae";
}
.cb-glyph-content-multi.cb-matrix::before {
  content: "\e93b";
}
.cb-glyph-content-multi.cb-matrix::after {
  content: "\e9af";
}
.cb-glyph-content-multi.cb-megaphone::before {
  content: "\e93c";
}
.cb-glyph-content-multi.cb-megaphone::after {
  content: "\e9b0";
}
.cb-glyph-content-multi.cb-menu::before {
  content: "\e93d";
}
.cb-glyph-content-multi.cb-menu::after {
  content: "\e9b1";
}
.cb-glyph-content-multi.cb-minus::before {
  content: "\e93e";
}
.cb-glyph-content-multi.cb-minus::after {
  content: "\e9b2";
}
.cb-glyph-content-multi.cb-nav-links::before {
  content: "\e93f";
}
.cb-glyph-content-multi.cb-nav-links::after {
  content: "\e9b3";
}
.cb-glyph-content-multi.cb-new-doc::before {
  content: "\e941";
}
.cb-glyph-content-multi.cb-new-doc::after {
  content: "\e9b5";
}
.cb-glyph-content-multi.cb-new-window::before {
  content: "\e942";
}
.cb-glyph-content-multi.cb-new-window::after {
  content: "\e9b6";
}
.cb-glyph-content-multi.cb-note::before {
  content: "\e947";
}
.cb-glyph-content-multi.cb-note::after {
  content: "\e9b9";
}
.cb-glyph-content-multi.cb-online-tool::before {
  content: "\e948";
}
.cb-glyph-content-multi.cb-online-tool::after {
  content: "\e9ba";
}
.cb-glyph-content-multi.cb-pending::before {
  content: "\e949";
}
.cb-glyph-content-multi.cb-pending::after {
  content: "\e9bb";
}
.cb-glyph-content-multi.cb-phone::before {
  content: "\e94a";
}
.cb-glyph-content-multi.cb-phone::after {
  content: "\e9bc";
}
.cb-glyph-content-multi.cb-phone-alt::before {
  content: "\e94b";
}
.cb-glyph-content-multi.cb-phone-alt::after {
  content: "\e9bd";
}
.cb-glyph-content-multi.cb-plus::before {
  content: "\e94c";
}
.cb-glyph-content-multi.cb-plus::after {
  content: "\e9be";
}
.cb-glyph-content-multi.cb-practice::before {
  content: "\e94d";
}
.cb-glyph-content-multi.cb-practice::after {
  content: "\e9bf";
}
.cb-glyph-content-multi.cb-practice-test::before {
  content: "\e94e";
}
.cb-glyph-content-multi.cb-practice-test::after {
  content: "\e9c0";
}
.cb-glyph-content-multi.cb-presentation::before {
  content: "\e94f";
}
.cb-glyph-content-multi.cb-presentation::after {
  content: "\e9c1";
}
.cb-glyph-content-multi.cb-print::before {
  content: "\e950";
}
.cb-glyph-content-multi.cb-print::after {
  content: "\e9c2";
}
.cb-glyph-content-multi.cb-problem-feedback::before {
  content: "\e951";
}
.cb-glyph-content-multi.cb-problem-feedback::after {
  content: "\e9c3";
}
.cb-glyph-content-multi.cb-raided-pencil::before {
  content: "\e955";
}
.cb-glyph-content-multi.cb-raided-pencil::after {
  content: "\e9c5";
}
.cb-glyph-content-multi.cb-resources::before {
  content: "\e956";
}
.cb-glyph-content-multi.cb-resources::after {
  content: "\e9c6";
}
.cb-glyph-content-multi.cb-rupee::before {
  content: "\e958";
}
.cb-glyph-content-multi.cb-rupee::after {
  content: "\e9c8";
}
.cb-glyph-content-multi.cb-save-date::before {
  content: "\e959";
}
.cb-glyph-content-multi.cb-save-date::after {
  content: "\e9c9";
}
.cb-glyph-content-multi.cb-science::before {
  content: "\e95d";
}
.cb-glyph-content-multi.cb-science::after {
  content: "\e9cc";
}
.cb-glyph-content-multi.cb-search::before {
  content: "\e95e";
}
.cb-glyph-content-multi.cb-search::after {
  content: "\e9cd";
}
.cb-glyph-content-multi.cb-series::before {
  content: "\e95f";
}
.cb-glyph-content-multi.cb-series::after {
  content: "\e9ce";
}
.cb-glyph-content-multi.cb-settings::before {
  content: "\e960";
}
.cb-glyph-content-multi.cb-settings::after {
  content: "\e9cf";
}
.cb-glyph-content-multi.cb-share::before {
  content: "\e961";
}
.cb-glyph-content-multi.cb-share::after {
  content: "\e9d0";
}
.cb-glyph-content-multi.cb-share-alt::before {
  content: "\e962";
}
.cb-glyph-content-multi.cb-share-alt::after {
  content: "\e9d1";
}
.cb-glyph-content-multi.cb-sound::before {
  content: "\e966";
}
.cb-glyph-content-multi.cb-sound::after {
  content: "\e9d4";
}
.cb-glyph-content-multi.cb-special-edition::before {
  content: "\e969";
}
.cb-glyph-content-multi.cb-special-edition::after {
  content: "\e9d5";
}
.cb-glyph-content-multi.cb-tag::before {
  content: "\e96b";
}
.cb-glyph-content-multi.cb-tag::after {
  content: "\e9d6";
}
.cb-glyph-content-multi.cb-task-complete::before {
  content: "\e96d";
}
.cb-glyph-content-multi.cb-task-complete::after {
  content: "\e9d8";
}
.cb-glyph-content-multi.cb-teacher-edition::before {
  content: "\e96e";
}
.cb-glyph-content-multi.cb-teacher-edition::after {
  content: "\e9d9";
}
.cb-glyph-content-multi.cb-team-leader::before {
  content: "\e96f";
}
.cb-glyph-content-multi.cb-team-leader::after {
  content: "\e9da";
}
.cb-glyph-content-multi.cb-test-scored::before {
  content: "\e970";
}
.cb-glyph-content-multi.cb-test-scored::after {
  content: "\e9db";
}
.cb-glyph-content-multi.cb-tuition-financial-aid::before {
  content: "\e971";
}
.cb-glyph-content-multi.cb-tuition-financial-aid::after {
  content: "\e9dc";
}
.cb-glyph-content-multi.cb-upload::before {
  content: "\e974";
}
.cb-glyph-content-multi.cb-upload::after {
  content: "\e9de";
}
.cb-glyph-content-multi.cb-user::before {
  content: "\e975";
}
.cb-glyph-content-multi.cb-video-lesson::before {
  content: "\e976";
}
.cb-glyph-content-multi.cb-video-lesson::after {
  content: "\e9df";
}
.cb-glyph-content-multi.cb-window::before {
  content: "\e978";
}
.cb-glyph-content-multi.cb-work-together::before {
  content: "\e979";
}
.cb-glyph-content-multi.cb-work-together::after {
  content: "\e9e0";
}
.cb-glyph-content-multi.cb-quote-left::before {
  content: "\e913";
}
.cb-glyph-content-multi.cb-quote-left::after {
  content: "\e91c";
}
.cb-glyph-content-multi.cb-quote-right::before {
  content: "\e91e";
}
.cb-glyph-content-multi.cb-quote-right::after {
  content: "\e919";
}
.cb-glyph-content-multi.cb-add-user::before {
  content: "\e9e2";
}
.cb-glyph-content-multi.cb-add-user::after {
  content: "\e9ec";
}
.cb-glyph-content-multi.cb-dollars::before {
  content: "\e9e3";
}
.cb-glyph-content-multi.cb-dollars::after {
  content: "\e9ed";
}
.cb-glyph-content-multi.cb-essential-math::before {
  content: "\e9e4";
}
.cb-glyph-content-multi.cb-essential-math::after {
  content: "\e9ee";
}
.cb-glyph-content-multi.cb-explore::before {
  content: "\e9e5";
}
.cb-glyph-content-multi.cb-explore::after {
  content: "\e9ef";
}
.cb-glyph-content-multi.cb-face-happy::before {
  content: "\e9e6";
}
.cb-glyph-content-multi.cb-face-happy::after {
  content: "\e9d3";
}
.cb-glyph-content-multi.cb-face-unhappy::before {
  content: "\e9e7";
}
.cb-glyph-content-multi.cb-face-unhappy::after {
  content: "\e9d3";
}
.cb-glyph-content-multi.cb-face-unsure::before {
  content: "\e9e8";
}
.cb-glyph-content-multi.cb-face-unsure::after {
  content: "\e9d3";
}
.cb-glyph-content-multi.cb-maths::before {
  content: "\e9ea";
}
.cb-glyph-content-multi.cb-maths::after {
  content: "\e9f3";
}
.cb-glyph-content-multi.cb-school::before {
  content: "\e9eb";
}
.cb-glyph-content-multi.cb-school::after {
  content: "\e9f4";
}
.cb-glyph-content-multi.cb-exam::before {
  content: "\e921";
}
.cb-glyph-content-multi.cb-exam::after {
  content: "\e944";
}
.cb-glyph-content-multi.cb-khan-academy::before {
  content: "\e90f";
}
.cb-glyph-content-multi.cb-send-scores::before {
  content: "\e925";
}
.cb-glyph-content-multi.cb-send-scores::after {
  content: "\e945";
}
.cb-glyph-content-multi.cb-submit-documents::before {
  content: "\e92a";
}
.cb-glyph-content-multi.cb-submit-documents::after {
  content: "\e946";
}
.cb-glyph-content-multi.cb-submit-portfolio::before {
  content: "\e932";
}
.cb-glyph-content-multi.cb-submit-portfolio::after {
  content: "\e952";
}
.cb-glyph-content-multi.cb-play::before {
  content: "\e953";
}
.cb-glyph-content-multi.cb-signed-in::before {
  content: "\e957";
}
.cb-glyph-content-multi.cb-signed-in::after {
  content: "\e954";
}
.cb-glyph-content-multi.cb-laptop::before {
  content: "\e96a";
}
.cb-glyph-content-multi.cb-laptop-check::before {
  content: "\e96a";
}
.cb-glyph-content-multi.cb-laptop-check::after {
  content: "\e968";
}
.cb-glyph-content-multi.cb-glyph-sm {
  font-size: calc(1rem * 2);
}
.cb-glyph-content-multi.cb-glyph-lg {
  font-size: calc(1rem * 4);
}
.cb-glyph-content-multi.cb-glyph-xl {
  font-size: calc(1rem * 6);
}
.cb-glyph-content-multi::before {
  color: #ffffff;
}
.cb-glyph-content-multi::after {
  color: #fedb00;
}
.cb-glyph-content-multi.cb-glyph-black1 {
  background-color: #1e1e1e;
}
.cb-glyph-content-multi.cb-glyph-black1::before {
  color: #ffffff;
}
.cb-glyph-content-multi.cb-glyph-black1::after {
  color: #ffffff;
}
.cb-glyph-content-multi.cb-glyph-white {
  background-color: #ffffff;
}
.cb-glyph-content-multi.cb-glyph-white::before {
  color: #1e1e1e;
}
.cb-glyph-content-multi.cb-glyph-white::after {
  color: #1e1e1e;
}
.cb-glyph-content-multi.cb-glyph-blue2 {
  background-color: #0077c8;
}
.cb-glyph-content-multi.cb-glyph-blue2::before {
  color: #ffffff;
}
.cb-glyph-content-multi.cb-glyph-blue2::after {
  color: #fedb00;
}
.cb-glyph-content-multi.cb-glyph-blue3 {
  background-color: #009cde;
}
.cb-glyph-content-multi.cb-glyph-blue3::before {
  color: #ffffff;
}
.cb-glyph-content-multi.cb-glyph-blue3::after {
  color: #1e1e1e;
}
.cb-glyph-content-multi.cb-glyph-blue4 {
  background-color: #71c5e8;
}
.cb-glyph-content-multi.cb-glyph-blue4::before {
  color: #ffffff;
}
.cb-glyph-content-multi.cb-glyph-blue4::after {
  color: #1e1e1e;
}
.cb-glyph-content-multi.cb-glyph-yellow1 {
  background-color: #fedb00;
}
.cb-glyph-content-multi.cb-glyph-yellow1::before {
  color: #1e1e1e;
}
.cb-glyph-content-multi.cb-glyph-yellow1::after {
  color: #1e1e1e;
}
.cb-glyph-content-multi.cb-glyph-purple1 {
  background-color: #702f8a;
}
.cb-glyph-content-multi.cb-glyph-purple1::before {
  color: #ffffff;
}
.cb-glyph-content-multi.cb-glyph-purple1::after {
  color: #1e1e1e;
}
.cb-glyph-content-multi.cb-glyph-purple2 {
  background-color: #a05eb5;
}
.cb-glyph-content-multi.cb-glyph-purple2::before {
  color: #ffffff;
}
.cb-glyph-content-multi.cb-glyph-purple2::after {
  color: #1e1e1e;
}
.cb-glyph-content-multi.cb-glyph-green1 {
  background-color: #3a913f;
}
.cb-glyph-content-multi.cb-glyph-green1::before {
  color: #ffffff;
}
.cb-glyph-content-multi.cb-glyph-green1::after {
  color: #1e1e1e;
}
.cb-glyph-content-multi.cb-glyph-green2 {
  background-color: #6cc24a;
}
.cb-glyph-content-multi.cb-glyph-green2::before {
  color: #ffffff;
}
.cb-glyph-content-multi.cb-glyph-green2::after {
  color: #1e1e1e;
}