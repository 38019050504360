.cb-table {
  width: 100%;
  color: #1e1e1e;
  background-color: transparent;
  font-size: 1rem;
  line-height: 1.5em;
}
.cb-table > thead {
  background: #505050;
  color: #ffffff;
}
.cb-table > thead > tr > th {
  font-weight: 700;
}
.cb-table th,
.cb-table td {
  padding: 11px 8px 12px 8px;
  vertical-align: top;
  border: 1px solid #d9d9d9;
  min-width: 80px;
}
.cb-table th[rowspan],
.cb-table td[rowspan] {
  vertical-align: middle;
}
.cb-table.cb-table-light > thead {
  background: #ffffff;
  color: #1e1e1e;
}
.cb-table.cb-table-light > thead > tr > th,
.cb-table.cb-table-light > thead > tr > td {
  border-top-width: 2px;
  border-bottom-width: 2px;
  padding: 11px 8px 11px 8px;
}
.cb-table th,
.cb-table td {
  padding: 11px 8px 12px 8px;
  vertical-align: top;
  border: 1px solid #d9d9d9;
  min-width: 80px;
}
.cb-table th[rowspan],
.cb-table td[rowspan] {
  vertical-align: middle;
}
.cb-table.cb-no-border > thead > tr > th,
.cb-table.cb-no-border > thead > tr > td,
.cb-table.cb-no-border > tbody > tr > th,
.cb-table.cb-no-border > tbody > tr > td,
.cb-table.cb-no-border > tfoot > tr > th,
.cb-table.cb-no-border > tfoot > tr > td {
  border-right: 0;
}
.cb-table.cb-no-border > thead > tr > th:first-child,
.cb-table.cb-no-border > thead > tr > td:first-child,
.cb-table.cb-no-border > tbody > tr > th:first-child,
.cb-table.cb-no-border > tbody > tr > td:first-child,
.cb-table.cb-no-border > tfoot > tr > th:first-child,
.cb-table.cb-no-border > tfoot > tr > td:first-child {
  border-left: 0;
}
.cb-table.cb-no-border > thead > tr:last-child > th,
.cb-table.cb-no-border > thead > tr:last-child > td,
.cb-table.cb-no-border > tbody > tr:last-child > th,
.cb-table.cb-no-border > tbody > tr:last-child > td,
.cb-table.cb-no-border > tfoot > tr:last-child > th,
.cb-table.cb-no-border > tfoot > tr:last-child > td {
  border-bottom: 0;
}
.cb-table.cb-no-border.cb-table-light > thead > tr:last-child > th,
.cb-table.cb-no-border.cb-table-light > thead > tr:last-child > td {
  border-bottom: 2px solid #d9d9d9;
}
.cb-table.cb-no-border-left-right > thead > tr > th,
.cb-table.cb-no-border-left-right > thead > tr > td,
.cb-table.cb-no-border-left-right > tbody > tr > th,
.cb-table.cb-no-border-left-right > tbody > tr > td,
.cb-table.cb-no-border-left-right > tfoot > tr > th,
.cb-table.cb-no-border-left-right > tfoot > tr > td {
  border-right: 0;
  border-left: 0;
}
.cb-table.cb-no-border-left-right > thead > tr:last-child > th,
.cb-table.cb-no-border-left-right > thead > tr:last-child > td,
.cb-table.cb-no-border-left-right > tbody > tr:last-child > th,
.cb-table.cb-no-border-left-right > tbody > tr:last-child > td,
.cb-table.cb-no-border-left-right > tfoot > tr:last-child > th,
.cb-table.cb-no-border-left-right > tfoot > tr:last-child > td {
  border-bottom: 0;
}
.cb-table.cb-no-border-left-right.cb-table-light > thead > tr:last-child > th,
.cb-table.cb-no-border-left-right.cb-table-light > thead > tr:last-child > td {
  border-bottom: 2px solid #d9d9d9;
}
.cb-table.cb-table-condensed th,
.cb-table.cb-table-condensed td {
  padding: 3px 4px 4px 4px;
  font-size: 0.875rem;
  line-height: 1.7142857143em;
}
.cb-table.cb-table-striped > tbody > tr:nth-of-type(even) {
  background-color: #ffffff;
}
.cb-table.cb-table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f0f0f0;
}
.cb-table.cb-table-striped-reverse > tbody > tr:nth-of-type(odd) {
  background-color: #ffffff;
}
.cb-table.cb-table-striped-reverse > tbody > tr:nth-of-type(even) {
  background-color: #f0f0f0;
}
.cb-table .cb-table-sort {
  padding: 0 !important;
  vertical-align: middle;
  border-left-width: 0;
  border-right-width: 0;
  background: #ffffff;
}
.cb-table .cb-table-sort a {
  padding: 5px 10px;
  text-decoration: none !important;
  display: block;
}
.cb-table .cb-table-sort a:hover, .cb-table .cb-table-sort a:focus, .cb-table .cb-table-sort a:active {
  text-decoration: none !important;
}
.cb-table .cb-table-sort a .cb-sort-desc,
.cb-table .cb-table-sort a .cb-sort-asc {
  color: #0077c8;
}
.cb-table .cb-table-sort a .cb-sort {
  color: #1e1e1e;
}
.cb-table .cb-table-sort a:hover {
  background-color: #e6edf8;
  cursor: pointer;
}
.cb-table .cb-table-sort:first-of-type {
  border-left-width: 1px;
}
.cb-table .cb-table-sort:last-of-type {
  border-right-width: 1px;
}
.cb-table.cb-table-condensed .cb-table-sort a {
  padding: 3px 4px 4px 4px;
}
.cb-table .cb-table-sort-joined a {
  color: #ffffff;
  text-decoration: none !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cb-table .cb-table-sort-joined a:hover, .cb-table .cb-table-sort-joined a:focus, .cb-table .cb-table-sort-joined a:active, .cb-table .cb-table-sort-joined a:visited {
  color: #ffffff;
}
.cb-table .cb-table-sort-joined a:hover span:nth-of-type(1), .cb-table .cb-table-sort-joined a:focus span:nth-of-type(1), .cb-table .cb-table-sort-joined a:active span:nth-of-type(1), .cb-table .cb-table-sort-joined a:visited span:nth-of-type(1) {
  text-decoration: underline;
}
.cb-table .cb-table-sort-joined a:hover span.cb-glyph, .cb-table .cb-table-sort-joined a:focus span.cb-glyph, .cb-table .cb-table-sort-joined a:active span.cb-glyph, .cb-table .cb-table-sort-joined a:visited span.cb-glyph {
  text-decoration: none !important;
}
.cb-table .cb-table-sort-joined a .cb-sort-desc,
.cb-table .cb-table-sort-joined a .cb-sort-asc,
.cb-table .cb-table-sort-joined a .cb-sort {
  color: #ffffff;
}
.cb-table .cb-table-sort-joined:hover, .cb-table .cb-table-sort-joined:focus {
  background-color: #1e1e1e;
  cursor: pointer;
}
.cb-table .cb-table-sort-joined:hover a, .cb-table .cb-table-sort-joined:focus a {
  color: #ffffff !important;
}
.cb-table .cb-table-sort-joined:hover a .cb-glyph, .cb-table .cb-table-sort-joined:focus a .cb-glyph {
  color: #ffffff !important;
}
.cb-table.cb-table-light .cb-table-sort-joined a {
  color: #1e1e1e !important;
}
.cb-table.cb-table-light .cb-table-sort-joined a:hover, .cb-table.cb-table-light .cb-table-sort-joined a:focus, .cb-table.cb-table-light .cb-table-sort-joined a:active, .cb-table.cb-table-light .cb-table-sort-joined a:visited {
  color: #1e1e1e;
}
.cb-table.cb-table-light .cb-table-sort-joined a:hover span:nth-of-type(1), .cb-table.cb-table-light .cb-table-sort-joined a:focus span:nth-of-type(1), .cb-table.cb-table-light .cb-table-sort-joined a:active span:nth-of-type(1), .cb-table.cb-table-light .cb-table-sort-joined a:visited span:nth-of-type(1) {
  text-decoration: underline;
}
.cb-table.cb-table-light .cb-table-sort-joined a:hover span.cb-glyph, .cb-table.cb-table-light .cb-table-sort-joined a:focus span.cb-glyph, .cb-table.cb-table-light .cb-table-sort-joined a:active span.cb-glyph, .cb-table.cb-table-light .cb-table-sort-joined a:visited span.cb-glyph {
  text-decoration: none !important;
}
.cb-table.cb-table-light .cb-table-sort-joined a .cb-sort-desc,
.cb-table.cb-table-light .cb-table-sort-joined a .cb-sort-asc {
  color: #0077c8;
}
.cb-table.cb-table-light .cb-table-sort-joined a .cb-sort {
  color: #1e1e1e;
}
.cb-table.cb-table-light .cb-table-sort-joined:hover {
  background-color: #e6edf8;
  cursor: pointer;
}
.cb-table.cb-table-light .cb-table-sort-joined:hover a,
.cb-table.cb-table-light .cb-table-sort-joined:hover .cb-sort,
.cb-table.cb-table-light .cb-table-sort-joined:hover .cb-glyph {
  color: #1e1e1e !important;
}
.cb-table.cb-table-light .cb-table-sort-joined:hover .cb-sort-desc,
.cb-table.cb-table-light .cb-table-sort-joined:hover .cb-sort-asc {
  color: #0077c8 !important;
}
.cb-table .cb-table-thumbnail {
  width: 72px;
  height: 72px;
  box-sizing: border-box;
  border: 1px solid #d9d9d9;
  background-color: #b2b2b2;
  position: relative;
  overflow: hidden;
}
.cb-table .cb-table-thumbnail img {
  width: 100%;
  z-index: 2;
  position: absolute;
}
.cb-table .cb-table-thumbnail::before {
  content: "?";
  color: #d9d9d9;
  font-size: 1.5rem;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cb-table .cb-table-thumbnail + p {
  display: inline-block;
  float: left;
}
.cb-table .cb-table-tip.cb-glyph {
  font-size: 0.75rem;
  line-height: 1em;
  display: inline-block;
  float: right;
}
.cb-table .cb-table-callout {
  background-color: #f0f0f0;
}
.cb-table > thead .cb-table-callout, .cb-table > thead.cb-table-callout {
  color: #1e1e1e !important;
}
.cb-table .cb-table-highlight {
  background-color: #fdf4ba !important;
}
.cb-table .cb-table-selected {
  background-color: #e6edf8 !important;
}
.cb-table.cb-table-expandable .cb-table-expandable-trigger {
  padding: 0 !important;
  min-width: 48px;
  max-width: 48px;
  width: 48px;
  height: 1px;
}
.cb-table.cb-table-expandable .cb-table-expandable-trigger .cb-btn {
  border: 0;
  border-radius: 0;
  height: 100% !important;
}
.cb-table.cb-table-expandable .cb-table-expandable-trigger .cb-btn:focus {
  box-shadow: inset 0 0 0 2px #1e1e1e;
}
.cb-table.cb-table-expandable .cb-table-expandable-trigger .cb-btn:hover {
  background: rgba(0, 0, 0, 0.16);
  box-shadow: none;
}
.cb-table.cb-table-expandable .cb-table-expandable-row td.cb-table-expandable-trigger-right {
  padding-right: 56px;
}
.cb-table.cb-table-expandable .cb-table-expandable-row td.cb-table-expandable-trigger-left {
  padding-left: 56px;
}
.cb-table.cb-table-expandable.cb-table-condensed .cb-table-expandable-trigger {
  min-width: 32px;
  max-width: 32px;
  width: 32px;
}
.cb-table.cb-table-expandable.cb-table-condensed .cb-table-expandable-trigger .cb-btn {
  width: 32px;
  height: 32px;
  line-height: 0.875em;
  font-size: 0.875rem;
  padding: 0;
}
.cb-table.cb-table-expandable.cb-table-condensed .cb-table-expandable-row td.cb-table-expandable-trigger-right {
  padding-right: 36px;
}
.cb-table.cb-table-expandable.cb-table-condensed .cb-table-expandable-row td.cb-table-expandable-trigger-left {
  padding-left: 36px;
}
.cb-table.cb-table-expandable.cb-table-striped tbody tr, .cb-table.cb-table-expandable.cb-table-striped-reverse tbody tr {
  background-color: #ffffff;
}
.cb-table.cb-table-expandable.cb-table-striped tbody .cb-stripe, .cb-table.cb-table-expandable.cb-table-striped-reverse tbody .cb-stripe {
  background-color: #f0f0f0;
}
.cb-table.cb-table-expandable.cb-table-striped tbody .cb-no-stripe, .cb-table.cb-table-expandable.cb-table-striped-reverse tbody .cb-no-stripe {
  background-color: #ffffff;
}

.cb-table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.cb-table-responsive > .cb-table {
  border: 0;
}

@media (max-width: 767.98px) {
  .cb-table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .cb-table-responsive-sm > .cb-table {
    border: 0;
  }
}
@media (max-width: 1023.98px) {
  .cb-table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .cb-table-responsive-md > .cb-table {
    border: 0;
  }
}
@media (max-width: 1247.98px) {
  .cb-table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .cb-table-responsive-lg > .cb-table {
    border: 0;
  }
}
@media (max-width: 1343.98px) {
  .cb-table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .cb-table-responsive-xl > .cb-table {
    border: 0;
  }
}
@media (max-width: 1439.98px) {
  .cb-table-responsive-2xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .cb-table-responsive-2xl > .cb-table {
    border: 0;
  }
}
@media (max-width: 1727.98px) {
  .cb-table-responsive-3xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .cb-table-responsive-3xl > .cb-table {
    border: 0;
  }
}
.cb-table-responsive- {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.cb-table-responsive- > .cb-table {
  border: 0;
}

.cb-table-scroll {
  overflow: auto;
}

.cb-table-mobile td {
  width: 50%;
}
.cb-table-mobile .cb-last-row td,
.cb-table-mobile .cb-last-row th {
  border-bottom-color: #888888;
  padding-bottom: 24px;
}
.cb-table-mobile .cb-last-row:last-of-type td,
.cb-table-mobile .cb-last-row:last-of-type th {
  border-bottom-color: #888888;
}
.cb-table-mobile .cb-last-row + tr td,
.cb-table-mobile .cb-last-row + tr th {
  padding-top: 24px;
}

.cb-table-sticky.cb-active-sticky {
  position: relative;
  overflow: auto;
  border: 1px solid #d9d9d9;
  border-top: 0;
}
.cb-table-sticky.cb-active-sticky .cb-table {
  border-bottom: 0px !important;
}
.cb-table-sticky.cb-active-sticky .cb-table > thead > tr > th:first-child,
.cb-table-sticky.cb-active-sticky .cb-table > thead > tr > td:first-child,
.cb-table-sticky.cb-active-sticky .cb-table > tbody > tr > th:first-child,
.cb-table-sticky.cb-active-sticky .cb-table > tbody > tr > td:first-child,
.cb-table-sticky.cb-active-sticky .cb-table > tfoot > tr > th:first-child,
.cb-table-sticky.cb-active-sticky .cb-table > tfoot > tr > td:first-child {
  border-left: 0;
}
.cb-table-sticky.cb-active-sticky .cb-table > thead > tr > th:last-child,
.cb-table-sticky.cb-active-sticky .cb-table > thead > tr > td:last-child,
.cb-table-sticky.cb-active-sticky .cb-table > tbody > tr > th:last-child,
.cb-table-sticky.cb-active-sticky .cb-table > tbody > tr > td:last-child,
.cb-table-sticky.cb-active-sticky .cb-table > tfoot > tr > th:last-child,
.cb-table-sticky.cb-active-sticky .cb-table > tfoot > tr > td:last-child {
  border-right: 0;
}
.cb-table-sticky.cb-active-sticky .cb-table > caption {
  border-left: 0;
  border-right: 0;
}
.cb-table-sticky.cb-active-sticky .cb-table > tbody > tr > th,
.cb-table-sticky.cb-active-sticky .cb-table > tbody > tr > td,
.cb-table-sticky.cb-active-sticky .cb-table > tfoot > tr > th,
.cb-table-sticky.cb-active-sticky .cb-table > tfoot > tr > td {
  border-bottom: 0;
}
.cb-table-sticky.cb-active-sticky .cb-table thead .cb-sticky-cell {
  background: #505050;
  color: #ffffff;
  border-right: 0;
}
.cb-table-sticky.cb-active-sticky .cb-table thead.cb-active-header {
  box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.1);
}
.cb-table-sticky.cb-active-sticky .cb-table thead.cb-active-column tr.cb-sticky-cell {
  border-right: 0;
}
.cb-table-sticky.cb-active-sticky .cb-table thead.cb-active-column tr .cb-sticky-cell-last {
  border-right: 1px solid #d9d9d9;
  box-shadow: 4px 0px 4px -2px rgba(0, 0, 0, 0.1);
}
.cb-table-sticky.cb-active-sticky .cb-table tbody .cb-sticky-cell {
  background: #ffffff;
  border-right: 0;
}
.cb-table-sticky.cb-active-sticky .cb-table tbody.cb-active-column .cb-sticky-cell-last {
  border-right: 1px solid #d9d9d9;
  box-shadow: 4px 0px 4px -2px rgba(0, 0, 0, 0.1);
}

.cb-table-sticky-css {
  display: block;
  width: 100%;
  overflow: auto;
  border: 1px solid #d9d9d9;
}
.cb-table-sticky-css .cb-table {
  position: relative;
  border-collapse: separate;
  border-spacing: 0;
}
.cb-table-sticky-css .cb-table > thead > tr > th,
.cb-table-sticky-css .cb-table > thead > tr > td,
.cb-table-sticky-css .cb-table > tbody > tr > th,
.cb-table-sticky-css .cb-table > tbody > tr > td,
.cb-table-sticky-css .cb-table > tfoot > tr > th,
.cb-table-sticky-css .cb-table > tfoot > tr > td {
  border-right: 0;
  border-top: 0;
}
.cb-table-sticky-css .cb-table > thead > tr > th:first-child,
.cb-table-sticky-css .cb-table > thead > tr > td:first-child,
.cb-table-sticky-css .cb-table > tbody > tr > th:first-child,
.cb-table-sticky-css .cb-table > tbody > tr > td:first-child,
.cb-table-sticky-css .cb-table > tfoot > tr > th:first-child,
.cb-table-sticky-css .cb-table > tfoot > tr > td:first-child {
  border-left: 0;
}
.cb-table-sticky-css .cb-table thead.cb-active-header tr th {
  position: sticky;
  z-index: 3;
  left: 0;
  top: -1px;
  background: #505050;
  box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.1);
}
.cb-table-sticky-css .cb-table thead.cb-active-header tr th.cb-active-column {
  z-index: 4;
  box-shadow: none;
}
.cb-table-sticky-css .cb-table tbody.cb-active-column tr th:nth-child(1),
.cb-table-sticky-css .cb-table tbody.cb-active-column tr td:nth-child(1) {
  position: sticky;
  left: 0px;
  top: -1px;
  z-index: 3;
  background: #ffffff;
  box-shadow: 4px 0px 4px -2px rgba(0, 0, 0, 0.1);
  border-right: 1px solid #d9d9d9;
}

.cb-loader-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: top;
  padding-top: 72px;
  z-index: 50000;
}

.cb-table-wrapper.with-top-scroll {
  overflow-x: auto;
}

.cb-table-top-scroll-holder {
  display: block;
  overflow-x: scroll;
  overflow-y: hidden;
}
.cb-table-top-scroll-holder .cb-table-top-scroll {
  height: 1px;
}

.cb-table-react.cb-table tbody th {
  font-weight: initial;
}
.cb-table-react.cb-table-header {
  position: absolute;
}
.cb-table-react.cb-table-header .cb-table-wrapper {
  overflow-x: hidden;
}
.cb-table-react.cb-table-header.cb-sticky-table {
  position: fixed;
  top: 0;
  z-index: 900;
}
.cb-table-react.cb-table-header table {
  width: unset;
}
.cb-table-react.cb-table-header table td.cb-table-sort a.focused {
  outline-offset: 1px;
  outline: #7ab6fd auto 1px;
}
.cb-table-react .cb-sticky-header-parent {
  position: sticky;
  top: -1px;
  z-index: 2;
}
.cb-table-react .cb-sticky-header-parent.cb-sticky-active {
  box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.1);
}
.cb-table-react .cb-sticky-header-parent .cb-table-sort:first-of-type {
  box-shadow: inset 1px 0px 0px #d9d9d9;
  border-left: 0 !important;
}
.cb-table-react.cb-sticky-table-column > thead th {
  background-color: #505050;
}
.cb-table-react.cb-sticky-table-column > thead th:first-of-type {
  box-shadow: inset -1px 0px 0px #ffffff;
  border-right: 0 !important;
}
.cb-table-react.cb-sticky-table-column > thead th:first-of-type + th {
  border-left: 0 !important;
}
.cb-table-react.cb-sticky-table-column .cb-sticky-column {
  position: sticky;
  left: 0px;
  z-index: 1;
  background-color: #ffffff;
  border-left: 0 !important;
  border-right: 0 !important;
  /* box-shadow: inset -11px -1px 5px -10px rgba(0, 0, 0, 0.1); */
  box-shadow: inset -1px 0px 0px #d9d9d9, inset 1px 0px 0px #d9d9d9;
}
.cb-table-react.cb-sticky-table-column .cb-sticky-column + th,
.cb-table-react.cb-sticky-table-column .cb-sticky-column + td {
  border-left: 0 !important;
}
.cb-table-react.cb-sticky-table-column .cb-stripe .cb-sticky-column {
  background-color: #f0f0f0 !important;
}
.cb-table-react.cb-sticky-table-column.cb-no-border .cb-sticky-column {
  box-shadow: inset -1px 0px 0px #d9d9d9 !important;
}
.cb-table-react.cb-sticky-table-column > thead th:first-child,
.cb-table-react.cb-sticky-table-column > thead .cb-table-sort:first-child {
  position: sticky;
  left: 0px;
  z-index: 2;
}